import React, { useEffect, useState } from 'react'

import Select from 'react-select'
// import { QueryGetBairros } from '../../../../graphql/services/Bairros'
import { FormLabel } from 'react-bootstrap'
import { useBairros } from './useBairros'

export function GetBairrosChosen({
  setValue,
  value,
  idCidade
}:{setValue:any, value:any, idCidade:number}) {
  let BairrosArray = []

  const [bairro, setBairro] = useState(null)
  const {bairros,fetchBairros} =  useBairros()

  useEffect(() => {
    setBairro(value)
  }, [value])

  useEffect(()=>{
    if (idCidade){
      fetchBairros(idCidade)
    }
  },[fetchBairros, idCidade])
  

  // console.log(bairros)
  // const { data } = QueryGetBairros({
  //   variables: {
  //     pagination: {
  //       pagina: 0,
  //       quantidade: 100,
  //     }
  //   }
  // })



  if (!bairros) {
    return (
      <>
        <div className='form-group'>
        <FormLabel className='form-label'>Bairros</FormLabel>
          {/* <label className='col-form-label'>Bairros Disponiveis:</label> */}
          <Select
            options={BairrosArray}
            onChange={(e) => {
              setBairro(e.value)
            }}
            value={BairrosArray.filter((e) => e.value)[0]}
            name='id_Bairro'
            placeholder='Bairros Disponiveis'
          />
        </div>
      </>
    )
  }
  BairrosArray.push({ value:'', label: '--selecione--' })
  bairros.map((options) => {
    BairrosArray.push({ value: options.id, label: options.bairro })
    return []
  })

  

  return (
    <>
      <div className='form-group'>
      <FormLabel className='form-label'>Bairros</FormLabel>
        {/* <label className='col-form-label'>Bairros Disponiveis:</label> */}
        <Select isMulti
          value={  bairro ? bairro : BairrosArray.find((e) => e.value === bairro) || null}
          options={BairrosArray}
          onChange={(itens) => {
            console.log(itens)
            setBairro(itens)
            setValue(itens.map(opt=>opt.value))
            // SetValueBairro(e.value)
          }}
          name='id_Bairro'
          placeholder='Bairros'
        />
      </div>
    </>
  )
}
