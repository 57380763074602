import {useState} from 'react'
import {ApiUrbit} from '../../../../../infra/http/urbit'

export const useZonaUso = () => {
  const [zonaUso, setZonaUso] = useState(null)

  async function fetchZonaUso() {

      return await ApiUrbit.get(`zoneamento`).then((data) => {
        setZonaUso(data)
      })

  }

  return {
    fetchZonaUso,
    zonaUso,
    setZonaUso
  }
}
