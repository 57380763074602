import { Button } from "../../../../../../../../components/UI/Buttons.tsx/Button"

type Props = {
}

export const NovaTese: React.FC<Props> = () => {

  return (
    <Button className="btn btn-primary">Nova Tese</Button>
  )


}