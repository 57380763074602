import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
type Props = {}

export const AbrirTese: React.FC<Props> = () => {
  return (
    <Form.Group as={Row} className='mb-3' controlId='formPlaintextEmail'>
      <Form.Label column sm='3'>
        Abrir Tese
      </Form.Label>
      <Col sm='9'>
        <Form.Select aria-label='Selecionar Tese'>
          <option>--selecionar--</option>
          <option value='1'>Tese 1</option>
          <option value='2'>Tese 2</option>
        </Form.Select>
      </Col>
    </Form.Group>

    // <Button className="btn btn-primary">Abrir Tese</Button>
  )
}
