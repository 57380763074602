import { useMutation, useQuery } from '@apollo/client'
import { TypesDeleteEmpreendimentoSituacaoResponse, TypesGetEmpreendimentoSituacaoByIDResponse, TypesGetEmpreendimentoSituacaoResponse, TypesPutEmpreendimentoSituacaoResponse } from '../type/empreendimento-situacao'
import { GET_EMPREENDIMENTO_SITUACAO, GET_EMPREENDIMENTO_SITUACAO_BY_ID } from '../query/empreendimento-situacao'
import { DELETE_EMPREENDIMENTO_SITUACAO, PUT_EMPREENDIMENTO_SITUACAO, SET_EMPREENDIMENTO_SITUACAO } from '../mutations/empreendimento-situacao'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TypesSetEmpreendimentoImovelResponse } from '../type'
import { putEmpreendimentoSituacaoFormData, putEmpreendimentoSituacaoFormSchema, setEmpreendimentoSituacaoFormData, setEmpreendimentoSituacaoFormSchema } from '../formValidations/EmpreendimentoSituacao'

interface Props {
  variables: any
}

export function QueryGetEmpreendimentoSituacao({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetEmpreendimentoSituacaoResponse>(GET_EMPREENDIMENTO_SITUACAO, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetEmpreendimentoSituacaoByID({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetEmpreendimentoSituacaoByIDResponse>(GET_EMPREENDIMENTO_SITUACAO_BY_ID, {
    variables: {...variables},
  })
  return {data, loading, error}
}

  
export function QueryGetEmpreendimentoRelatorioSituacao({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetEmpreendimentoSituacaoByIDResponse>(GET_EMPREENDIMENTO_SITUACAO_BY_ID, {
    variables: {...variables},
  })
  return {data, loading, error}
}


export function MutationDeleteEmpreendimentoSituacao() {
  const [deleteEmpreendimentoSituacao, {loading, data, error}] = useMutation<TypesDeleteEmpreendimentoSituacaoResponse>(
    DELETE_EMPREENDIMENTO_SITUACAO,
    {
      refetchQueries: [GET_EMPREENDIMENTO_SITUACAO],
    }
  )

  async function HandleDeleteEmpreendimentoSituacao({variables}: Props) {
    try {
      const result = await deleteEmpreendimentoSituacao({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTO_SITUACAO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEmpreendimentoSituacao, loading, error, data}
}

export function MutationSetEmpreendimentoSituacao() {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm<setEmpreendimentoSituacaoFormData>({
    resolver: zodResolver(setEmpreendimentoSituacaoFormSchema),
  })

  console.log(errors)

  const [setEmpreendimentoSituacaoBody, {error, loading, data: DataSetEquipe}] =
    useMutation<TypesSetEmpreendimentoImovelResponse>(SET_EMPREENDIMENTO_SITUACAO, {
      refetchQueries: [GET_EMPREENDIMENTO_SITUACAO],
    })

  async function FormEmpreendimentoSituacao(data: setEmpreendimentoSituacaoFormData) {
    await setEmpreendimentoSituacaoBody({
      variables: {
        situacao: {
          nome: data.nome,
          cor: data.cor
        },
      },
    })

  }

  return {register, setValue, handleSubmit, FormEmpreendimentoSituacao, loading, errors, error, DataSetEquipe}
}


export function MutationPutEmpreendimentoSituacao() {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm<putEmpreendimentoSituacaoFormData>({
    resolver: zodResolver(putEmpreendimentoSituacaoFormSchema),
  })
  console.log(errors)


  const [setEmpreendimentoSituacaoBody, {error, loading, data: DataSetEquipe}] =
    useMutation<TypesPutEmpreendimentoSituacaoResponse>(PUT_EMPREENDIMENTO_SITUACAO, {
      refetchQueries: [GET_EMPREENDIMENTO_SITUACAO],
    })

  async function FormEmpreendimentoSituacao(data: putEmpreendimentoSituacaoFormData) {
    await setEmpreendimentoSituacaoBody({
      variables: {
        situacao: {
          id: data.id,
          nome: data.nome,
          cor: data.cor
        },
        putEmpreendimentoSituacaoId: data.id
      },
    })

  }


  return {setValue, register, handleSubmit, FormEmpreendimentoSituacao, loading, errors, error, DataSetEquipe,control}
}
