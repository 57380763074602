import {useState} from 'react'
import {useTeseStore} from '../../../../../context/DataContext'
import MapView from './MapView'
import useLayoutControl from '../../../../../hooks/useLayoutControl'

const Mapa = () => {
  const {windowHeight} = useLayoutControl()
  const {imoveis} = useTeseStore()
  const [showMap, setShowMap] = useState(false)
  function handleShowPanel() {
    setShowMap(false)
  }

  return (
    <>
      <div className='all-start-between' style={{height: windowHeight.h}}>
        {/* <Scrollbars style={{ height: windowHeight-100}}> */}
        {/* <Filtro height={windowHeight.h} /> */}
        {/* </Scrollbars> */}
        <div
          id='map'
          className='flex-row position-relative'
          style={{width: '100%', height: windowHeight.h}}
        >
          {showMap && (
            <button
              className='btn btn-primary position-absolute top-0 end-0 m-3 z-index-1'
              onClick={handleShowPanel}
            >
              Mostrar Painel
            </button>
          )}

          <MapView height={windowHeight.h} imoveis={imoveis}></MapView>
        </div>
      </div>
    </>
  )
}

export default Mapa
