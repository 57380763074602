import {BiCheck, BiDownArrow, BiLinkExternal, BiPlus, BiTrash, BiUpArrow} from 'react-icons/bi'
import {RangeSlider} from '../../../components/UI'
import {useImoveisStore} from '../context/DataContext'
import {formatNumber} from '../../../components/util'
import SelectDormitoriosFiltro from '../../inteligencia-mercado/components/UI/SelectDormitoriosFiltro'
import SelectVagasFiltro from '../../inteligencia-mercado/components/UI/SelectVagasFiltro'
import DataTable from 'react-data-table-component'
import {customStylesTable} from '../../inteligencia-mercado/components/Common/common'
import {useEffect, useMemo, useState} from 'react'
import {BoxPlotChart} from './Amostras/BoxPlotChart'
import {max, min, quantile, standardDeviation} from 'simple-statistics'
import {Loader} from '../../../components/UI/Loader/Loader'
import {Exportar} from '../partials/BtnExportar'

type Props = {
  label: string
}

type Variaveis = {
  m2: {
    min: number
    max: number
    mediana: number
    q1: number
    q3: number
    desvioPadrao: number
    iqr: number
    limitInferior: number
    limitSuperior: number
  }
  ticket: {
    min: number
    max: number
    mediana: number
    q1: number
    q3: number
    desvioPadrao: number
    iqr: number
    limitInferior: number
    limitSuperior: number
  }
  amostrasFinalTicket: number[]
  amostrasFinalPrecom2: number[]
}
type FilterLocal = {
  faixaMetragem?: [number, number]
  faixaTicket?: [number, number]
  precoMedioM2?: [number, number]
  tipologia?: string[]
  dormitorios?: number[]
  vagas?: number[]
  suites?: number[]
  distancia?: [number, number]
}

export function Amostras({label}: Props) {
  const {filtro, imoveis, add, remove, setFiltro} = useImoveisStore()
  const [filtroLocal, setFiltroLocal] = useState<FilterLocal>({
    faixaMetragem: filtro.faixaMetragem,
    faixaTicket: filtro.faixaTicket,
    precoMedioM2: filtro.precoMedioM2,
    tipologia: filtro.tipologia,
    dormitorios: filtro.dormitorios,
    vagas: filtro.vagas,
    suites: filtro.suites,
    distancia: filtro.distancia,
  })
  const [variaveis, setVariaveis] = useState<Variaveis>({
    m2: {
      min: null,
      max: null,
      mediana: null,
      q1: null,
      q3: null,
      desvioPadrao: null,
      iqr: null,
      limitInferior: null,
      limitSuperior: null,
    },
    ticket: {
      min: null,
      max: null,
      mediana: null,
      q1: null,
      q3: null,
      desvioPadrao: null,
      iqr: null,
      limitInferior: null,
      limitSuperior: null,
    },
    amostrasFinalTicket: [],
    amostrasFinalPrecom2: [],
  })

  function onchangeFilter(tipo: string, valor: string | number | []) {
    let fiterTemp = filtroLocal
    fiterTemp[tipo] = valor
    setFiltroLocal({...fiterTemp})
  }

  function applyFilter() {
    setFiltro({...filtro, ...filtroLocal})
  }

  useEffect(() => {
    if (filtro) {
      setFiltroLocal({
        faixaMetragem: filtro.faixaMetragem,
        faixaTicket: filtro.faixaTicket,
        precoMedioM2: filtro.precoMedioM2,
        tipologia: filtro.tipologia,
        dormitorios: filtro.dormitorios,
        vagas: filtro.vagas,
        suites: filtro.suites,
        distancia: filtro.distancia,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro])

  let columnsDataRemoved = useMemo(
    () => {
      return [
        {
          name: 'Adicionar',
          selector: (row) => {
            return (
              <button
                onClick={() => add(row.id)}
                className='btn btn-icon  btn-sm  '
                style={{color: '#1900ff'}}
              ><BiPlus className='fs-1' /></button>
            )
          },
          sortable: true,
          wrap: true,
          width: '120px',
        },
        {
          name: '',
          selector: (row) => {
            return (
              <a href={row.link} target='_blank' rel='noreferrer' className='fs-2'>
                <BiLinkExternal />
              </a>
            )
          },
          sortable: true,
          wrap: true,
          width: '55px',
        },
        {
          name: 'Endereco',
          selector: (row) => (row.endereco ? row.endereco : 'Não informado'),
          sortable: true,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Área',
          selector: (row) => row.area,
          sortable: true,
          width: '100px',
        },
        {
          name: 'Quartos/Suítes',
          selector: (row) => row.quartos + '/' + (row.suites ? row.suites : 0) + '',
          sortable: true,
          width: '170px',
          center: true,
        },
        {
          name: 'Banheiros',
          selector: (row) => row.banheiros,
          sortable: true,
          center: true,
        },
        {
          name: 'Vagas',
          selector: (row) => row.vagas,
          sortable: true,
          width: '120px',
          center: true,
        },
        {
          name: 'Valor',
          selector: (row) => formatNumber(row.valor, 'currency_inteiro'),
          sortable: true,
          width: '120px',
          right: true,
        },
        {
          name: 'Valor m²',
          selector: (row) => formatNumber(row.preco_m2, 'currency_inteiro'),
          sortable: true,
          right: true,
        },
        {
          name: 'Ano Construção',
          selector: (row) => row.ano_construcao,
          sortable: true,
          right: true,
        }
      ]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variaveis]
  )
  const columnsData = useMemo(() => {
    return [
      {
        name: '',
        selector: (row) => {
          return (
            <button
              onClick={() => remove(row.id)}
              className='btn btn-icon  btn-sm  '
              style={{color: '#ff0000'}}
            >
              <BiTrash className='fs-1' />
            </button>
          )
        },
        sortable: true,
        wrap: true,
        width: '70px',
      },
      {
        name: '',
        selector: (row) => {
          return (
            <a href={row.link} target='_blank' rel='noreferrer' className='fs-2'>
              <BiLinkExternal />
            </a>
          )
        },
        sortable: true,
        wrap: true,
        width: '55px',
      },
      {
        name: 'Limite R$/m²',
        selector: (row) => row.preco_m2,
        format: (row) => {
          if (row.preco_m2 > variaveis.m2.limitSuperior) {
            return (
              <div>
                <BiUpArrow className='fs-5 text-danger'></BiUpArrow>{' '}
              </div>
            )
          } else if (row.preco_m2 < variaveis.m2.limitInferior) {
            return (
              <div>
                <BiDownArrow className='fs-5 text-danger'></BiDownArrow>{' '}
              </div>
            )
          } else {
            return (
              <div>
                <BiCheck className='fs-5 text-success'></BiCheck>{' '}
              </div>
            )
          }
        },
        sortable: true,
        center: true,
        wrap: false,
        width: '120px',
        sortField: 'limitM2',
        sortFunction: dateSort,
      },
      {
        name: 'Limite R$',
        selector: (row) => {
          if (row.valor > variaveis.ticket.limitSuperior) {
            return 1
          } else if (row.valor < variaveis.ticket.limitInferior) {
            return -1
          } else {
            return 0
          }
        },
        format: (row) => {
          if (row.valor > variaveis.ticket.limitSuperior) {
            return (
              <div>
                {' '}
                <BiUpArrow className='fs-5 text-danger'></BiUpArrow>
              </div>
            )
          } else if (row.valor < variaveis.ticket.limitInferior) {
            return (
              <div>
                <BiDownArrow className='fs-5 text-danger'></BiDownArrow>
              </div>
            )
          } else {
            return (
              <div>
                <BiCheck className='fs-5 text-success'></BiCheck>
              </div>
            )
          }
        },
        sortable: true,
        center: true,
        wrap: false,
        width: '150px',
        sortField: 'limitTicket',
        sortFunction: dateSortTicket,
      },
      {
        name: 'Endereco',
        selector: (row) => (row.endereco ? row.endereco : 'Não informado'),
        sortable: true,
        wrap: true,
        width: '200px',
      },
      {
        name: 'Área',
        selector: (row) => row.area,
        sortable: true,
        width: '100px',
      },
      {
        name: 'Quartos/Suítes',
        selector: (row) => row.quartos,
        format: (row) => row.quartos + '/' + (row.suites ? row.suites : 0),
        sortable: true,
        width: '170px',
        center: true,
      },
      {
        name: 'Banheiros',
        selector: (row) => row.banheiros,
        sortable: true,
        center: true,
      },
      {
        name: 'Vagas',
        selector: (row) => row.vagas,
        sortable: true,
        width: '120px',
        center: true,
      },
      {
        name: 'Valor',
        selector: (row) => row.valor,
        format: (row) => formatNumber(row.valor, 'currency_inteiro'),
        sortable: true,
        width: '120px',
        right: true,
      },
      {
        name: 'Valor m²',
        selector: (row) => row.preco_m2,
        format: (row) => formatNumber(row.preco_m2, 'currency_inteiro'),
        sortable: true,
        right: true,
      },
      {
        name: 'Ano Construção',
        selector: (row) => row.ano_construcao,
        sortable: true,
        right: true,
      }
    ]

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variaveis])

  useEffect(() => {
    let amostrasTicket = []
    let amostrasPrecom2 = []
    let amostrasFinalTicket = []
    let amostrasFinalPrecom2 = []
    let minTicket = 0
    let maxTicket = 0
    let minPrecoM2 = 0
    let maxPrecom2 = 0
    let quantTicket = []
    let quantPrecom2 = []
    let iqrPrecom2 = 0
    let iqrTicket = 0
    let desvioTicket = 0
    let desvioPrecom2 = 0

    if (imoveis && imoveis.length > 0) {
      // eslint-disable-next-line array-callback-return
      imoveis.map((item, index) => {
        if (item.active) {
          amostrasTicket.push(item.valor)
          amostrasPrecom2.push(item.valor / item.area)
        }
      })
      
      quantTicket = amostrasTicket.length > 0 ? quantile(amostrasTicket, [0.25, 0.5, 0.75]) : []
      quantPrecom2 = amostrasPrecom2.length > 0 ? quantile(amostrasPrecom2, [0.25, 0.5, 0.75]) : []
      minTicket = amostrasTicket.length > 0 ? min(amostrasTicket) : 0
      maxTicket = amostrasTicket.length > 0 ? max(amostrasTicket) : 0

      amostrasFinalTicket.push({
        x: 'Amostras',
        y: [minTicket, ...quantTicket, maxTicket],
        id: 1,
      })
      minPrecoM2 = amostrasPrecom2.length > 0 ? min(amostrasPrecom2) : 0
      maxPrecom2 = amostrasPrecom2.length > 0 ? max(amostrasPrecom2) : 0
      amostrasFinalPrecom2.push({
        x: 'Amostras',
        y: [minPrecoM2, ...quantPrecom2, maxPrecom2],
        id: 1,
      })

      iqrPrecom2 = quantPrecom2[2] - quantPrecom2[0]
      iqrTicket = quantTicket[2] - quantTicket[0]

      desvioPrecom2 = amostrasPrecom2.length > 0 ? standardDeviation(amostrasPrecom2) : 0
      desvioTicket = amostrasTicket.length > 0 ? standardDeviation(amostrasTicket) : 0
    }

    let limitInferiorM2 = quantPrecom2[0] - 1.5 * iqrPrecom2
    let limitInferiorTicket = quantTicket[0] - 1.5 * iqrTicket

    let limitSuperiorM2 = quantPrecom2[2] + 1.5 * iqrPrecom2
    let limitSuperiorTicket = quantTicket[2] + 1.5 * iqrTicket
    setVariaveis({
      m2: {
        min: minPrecoM2,
        max: maxPrecom2,
        mediana: quantPrecom2[1],
        q1: quantPrecom2[0],

        q3: quantPrecom2[2],
        desvioPadrao: desvioPrecom2,
        iqr: iqrPrecom2,
        limitInferior: limitInferiorM2 < 0 ? 0 : limitInferiorM2,
        limitSuperior: limitSuperiorM2,
      },
      ticket: {
        min: minTicket,
        max: maxTicket,
        mediana: quantTicket[1],
        q1: quantTicket[0],

        q3: quantTicket[2],
        desvioPadrao: desvioTicket,
        iqr: iqrTicket,
        limitInferior: limitInferiorTicket < 0 ? 0 : limitInferiorTicket,
        limitSuperior: limitSuperiorTicket,
      },
      amostrasFinalTicket: amostrasFinalTicket,
      amostrasFinalPrecom2: amostrasFinalPrecom2,
    })
  }, [imoveis, filtro])

  const conditionalRowStyles = [
    {
      when: (row: {active: any}) => !row.active,
      style: () => ({
        backgroundColor: '#fcebee',
      }),
    },
  ]

  function onChange(e) {
    console.log(e)
  }

  function dateSort(rowA, rowB) {
    let limitA = null
    let limitB = null
    if (rowA.preco_m2 > variaveis.m2.limitSuperior) {
      limitA = 1
    } else if (rowA.preco_m2 < variaveis.m2.limitInferior) {
      limitA = -1
    } else {
      limitA = 0
    }

    if (rowB.preco_m2 > variaveis.m2.limitSuperior) {
      limitB = 1
    } else if (rowB.preco_m2 < variaveis.m2.limitInferior) {
      limitB = -1
    } else {
      limitB = 0
    }

    const a = limitA
    const b = limitB

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  function dateSortTicket(rowA, rowB) {
    let limitA = null
    let limitB = null
    if (rowA.valor > variaveis.ticket.limitSuperior) {
      limitA = 1
    } else if (rowA.valor < variaveis.ticket.limitInferior) {
      limitA = -1
    } else {
      limitA = 0
    }

    if (rowB.valor > variaveis.ticket.limitSuperior) {
      limitB = 1
    } else if (rowB.valor < variaveis.ticket.limitInferior) {
      limitB = -1
    } else {
      limitB = 0
    }

    const a = limitA
    const b = limitB

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10' style={{height: '92.1vh'}}>
        <div className='header-report d-flex all-start-between '>
          <div>
            <h1 className='fw-bolder'>Definição da amostra</h1>
            <span>Imóveis anunciados nos ultimos 60 dias.</span>
          </div>
          <div>
            <Exportar data={imoveis}></Exportar>
          </div>
        </div>

        <div className='content pt-10'>
          <div className=' g-5 g-xl-8'>
            <div className='card mb-6'>
              <form className='form-horizontal'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='  col-md-6 col-lg-6  col-xl-4 mb-2  pe-10'>
                      <label className='form-label form-label'>Área Privativa</label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('faixaMetragem', e)
                        }}
                        defaultValue={
                          filtroLocal?.faixaMetragem ? filtroLocal.faixaMetragem : [0, 1000]
                        }
                        min={0}
                        max={1000}
                        step={10}
                        formatTip={(value) => `${formatNumber(value, 'inteiro')} m²`}
                        showHideImput={true}
                      />
                      {/* <input type='text' className='form-control' placeholder='name@example.com' /> */}
                    </div>

                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10'>
                      <label className='form-label form-label'>Ticket </label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('faixaTicket', e)
                        }}
                        defaultValue={
                          filtroLocal?.faixaTicket ? filtroLocal.faixaTicket : [100000, 30000000]
                        }
                        min={100000}
                        max={30000000}
                        step={100000}
                        formatTip={(value) => {
                          if (value > 1000000) {
                            return `${value / 1000000}Mi `
                          } else {
                            return `${formatNumber(value, 'currency')} `
                          }
                        }}
                        showHideImput={true}
                      />
                    </div>
                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10'>
                      <label className='form-label form-label'>Preço m2 </label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('precoMedioM2', e)
                        }}
                        defaultValue={
                          filtroLocal?.precoMedioM2 ? filtroLocal.precoMedioM2 : [0, 50000]
                        }
                        min={0}
                        max={50000}
                        step={100}
                        formatTip={(value) => `${formatNumber(value, 'currency')} `}
                        showHideImput={true}
                      />
                    </div>

                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10 pt-10'>
                      <label className='form-label form-label'>Dormitórios</label>
                      <SelectDormitoriosFiltro
                        onChange={(e) => {
                          onchangeFilter('dormitorios', e)
                        }}
                        selected={filtro?.dormitorios ? filtro.dormitorios : []}
                      ></SelectDormitoriosFiltro>
                    </div>
                    <div className=' col-md-6 col-lg-6  col-xl-4 mb-2  pe-10 pt-10'>
                      <label className='form-label form-label'>Vagas</label>
                      <SelectVagasFiltro
                        onChange={(e) => {
                          onchangeFilter('vagas', e)
                        }}
                        selected={filtro?.vagas ? filtro.vagas : []}
                      ></SelectVagasFiltro>
                    </div>

                    {/* <div className='  col-md-6 col-lg-6  col-xl-4 mb-2  pe-10 pt-10'>
                      <label className='form-label form-label'>Distância</label>
                      <RangeSlider
                        onChange={(e) => {
                          onchangeFilter('distancia', e)
                        }}
                        defaultValue={filtroLocal?.distancia ? filtroLocal.distancia : [0, 800]}
                        min={0}
                        max={800}
                        step={1}
                        formatTip={(value) => `${formatNumber(value, 'inteiro')} m`}
                        showHideImput={true}
                      />
                    </div> */}
                  </div>
                </div>
                <div className='card-footer'>
                  <button type='button' className='btn btn-primary' onClick={applyFilter}>
                    Aplicar Filtro
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className='content '>
            <div className=' g-5 g-xl-8'>
              <div className='card mb-6'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-8'>
                      <BoxPlotChart
                        className={'col-12'}
                        title={''}
                        subtitle={'Preço por m²'}
                        data={variaveis.amostrasFinalPrecom2}
                        onChange={onChange}
                      ></BoxPlotChart>
                      <BoxPlotChart
                        className={'col-12'}
                        title={''}
                        subtitle={'Ticket'}
                        data={variaveis.amostrasFinalTicket}
                        onChange={onChange}
                      ></BoxPlotChart>
                    </div>

                    <div className='col-4'>
                      <table className='table table-striped'>
                        <thead>
                          <tr>
                            <th> </th>
                            <th className='text-center text-bold'> Preço/m²</th>
                            <th className='text-center text-bold'>Ticket</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Min</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.min, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.min, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>Q1</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.q1, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.q1, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>Mediana</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.mediana, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.mediana, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>Q3</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.q3, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.q3, 'currency_inteiro')}
                            </th>
                          </tr>{' '}
                          <tr>
                            <th>Máx</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.max, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.max, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>Desvio Padrão</th>
                            <th className='text-right'>
                              {' '}
                              {formatNumber(variaveis.m2.desvioPadrao, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.desvioPadrao, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>IQR</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.iqr, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.iqr, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>Limite Inferior</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.limitInferior, 'currency_inteiro')}{' '}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.limitInferior, 'currency_inteiro')}
                            </th>
                          </tr>
                          <tr>
                            <th>Limite Superior</th>
                            <th className='text-right'>
                              {formatNumber(variaveis.m2.limitSuperior, 'currency_inteiro')}
                            </th>
                            <th className='text-right'>
                              {formatNumber(variaveis.ticket.limitSuperior, 'currency_inteiro')}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=' g-5 g-xl-8'>
            <div className='card bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance p-0'>
              <div className='p-10'>
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                      Itens Computados
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link ' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                      Não Computados
                    </a>
                  </li>
                  {/* <li className='nav-item'>
                    <a className='nav-link ' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                      Mapa
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link ' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                      Estatisticas gerais
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                      Gráficos
                    </a>
                  </li> */}
                </ul>
                <div className='tab-content' id='myTabContent'>
                  <div className='tab-pane fade  show active' id='kt_tab_pane_2' role='tabpanel'>
                    <div className='row'>
                      <div className='col-12'>

                        <div >
                    <h4 className='py-5'>Total de amostras:<b> {imoveis ? imoveis.filter((item) => item.active).length : 0 }</b></h4>
                        </div>
                        {columnsData ? (
                          <DataTable
                            title='Mercado Secundário'
                            noHeader={true}
                            customStyles={customStylesTable}
                            columns={columnsData}
                            subHeader={false}
                            data={imoveis.filter((item) => item.active)}
                            pagination={true}
                            conditionalRowStyles={conditionalRowStyles}
                            highlightOnHover
                            pointerOnHover
                            striped
                            dense
                            paginationPerPage={20}
                            onColumnOrderChange={(cols) => console.log(cols)}
                          />
                        ) : (
                          <Loader text={'Carregando...'}></Loader>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='tab-pane fade ' id='kt_tab_pane_3' role='tabpanel'>
                    <div className='row'>
                      <div className='col-12'>
                        {columnsData ? (
                          <DataTable
                            title='Mercado Secundário'
                            noHeader={true}
                            customStyles={customStylesTable}
                            columns={columnsDataRemoved}
                            subHeader={false}
                            data={imoveis.filter((item) => !item.active)}
                            pagination={true}
                            conditionalRowStyles={conditionalRowStyles}
                            highlightOnHover
                            pointerOnHover
                            striped
                            // responsive
                            dense
                            paginationPerPage={20}
                            onColumnOrderChange={(cols) => console.log(cols)}
                          />
                        ) : (
                          <Loader text={'Carregando..'}></Loader>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className='tab-pane fade ' id='kt_tab_pane_1' role='tabpanel'>
                    <div className='row'>
                      <div className='col-12'>
                        <MapViewRevenda
                          center={center}
                          layer={[layersList]}
                          json={json}
                          zoom={15}
                          dataImoveis={filtered}
                          style={layerConfig.style}
                        >
                          <Legenda
                            style={layerConfig.style}
                            title={'Renda'}
                            visivel={true}
                          ></Legenda>
                        </MapViewRevenda>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
                    <PivotTable itens={filtered}></PivotTable>
                  </div> */}
                  {/* <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
                    <div className='row'>
                      <div className='col-6'>
                        <FaixaMetragem itens={filtered}></FaixaMetragem>
                      </div>
                      <div className='col-6'>
                        <FaixaTicket itens={filtered}></FaixaTicket>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <NumeroOfertas itens={filtered}></NumeroOfertas>
                      </div>
                      <div className='col-6'>
                        <PrecoMedioM2 itens={filtered}></PrecoMedioM2>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-6'>
                        <TicketMedio itens={filtered}></TicketMedio>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
