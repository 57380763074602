import {useState} from 'react'
import {ApiUrbit} from '../../../../../infra/http/urbit'

export const useBairros = () => {
  const [bairros, setBairros] = useState(null)

  async function fetchBairros(idCidade) {
    if (idCidade) {
      return await ApiUrbit.get(`bairros/${idCidade}`).then((data) => {
        setBairros(data)
      })
    }
  }

  return {
    fetchBairros,
    bairros,
  }
}
