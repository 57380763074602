/* eslint-disable jsx-a11y/anchor-is-valid */
import {useHistory} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {MutationSetUsuario} from '../../../graphql/services/User'
import Swal from 'sweetalert2'
import {FormInputAndLabel} from '../../../components/UI/Inputs/FormInputAndLabel'
import {FormInputMaskAndLabel} from '../../../components/UI/Inputs/FormInputMaskAndLabel'
import {ErrorMessageComponent} from '../../../backoffice/components/UI/ErrorMessage'
import {APIN8N} from '../../../../infra/http/n8n'

export function Registration() {
  const navigate = useHistory()
  const {FormSetUsuario, errors, handleSubmit, register} = MutationSetUsuario()

  const onSubmit = async (data) => {
    if (data.confirmarSenha !== data.senha) {
      Swal.fire('', 'As senhas não coincidem.', 'info')
    } else {
      FormSetUsuario(data)
        .then((result) => {
          if (typeof result.errors !== 'undefined' && result.errors.length > 0) {
            Swal.fire('', result.errors[0].message, 'info')
          } else {
            APIN8N.get(
              `/f13da61d-dabb-4e14-a3d3-c8c0e5827115?telefone=${data.telefone}&nome=${data.nome}&email=${data.email}&perfil=novo`
            )
            Swal.fire('Sucesso!', 'Usuario criado com sucesso.', 'success')
            navigate.push('/')
          }
        })
        .catch((error) => {
          Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
        })
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Criar uma Conta</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='Nome Completo'
            className='form-control form-control-solid'
            placeholder='Ex: João da Silva'
            register={register('nome')}
            error={errors ? errors.nome?.message : ''}
          />
        </div>
        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='E-mail'
            className='form-control form-control-solid'
            placeholder='Ex: exemplo@email.com'
            register={register('email')}
            error={errors ? errors.email?.message : ''}
          />
        </div>
        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='Senha'
            type='password'
            className='form-control form-control-solid'
            placeholder='Digite sua senha'
            register={register('senha')}
            error={errors ? errors.senha?.message : ''}
          />
        </div>
        <div className='col-xl-12 mb-3'>
          <FormInputAndLabel
            label='Confirmar Senha'
            type='password'
            className='form-control form-control-solid'
            placeholder='Digite novamente sua senha'
            register={register('confirmarSenha')}
            error={errors ? errors.confirmarSenha?.message : ''}
          />
        </div>
        <div className='col-xl-12 mb-3'>
          <FormInputMaskAndLabel
            mask='(99) 99999-9999'
            maskChar='_'
            label='Telefone'
            className='form-control form-control-solid'
            placeholder='(XX) XXXXX-XXXX'
            register={register('telefone')}
            error={errors ? errors.telefone?.message : ''}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='aceite'
            required
            {...register('aceite', {required: true})}
            // register={register("aceite")}
          />

          <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='aceite'>
            Eu aceito{' '}
            <Link target='_blank' to='/auth/terms' className='ms-1 link-primary'>
              os termos e condições
            </Link>
            .
          </label>
        </div>
        {errors.aceite && <ErrorMessageComponent message={errors.aceite.message} />}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='all-center '>
        <Link to='/'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary me-8'
          >
            Retornar
          </button>
        </Link>
        <button type='submit' id='kt_sign_up_submit' className='btn btn-lg btn-primary  '>
          <span className='indicator-label'>Enviar</span>
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
