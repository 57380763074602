import React, {useEffect, useState} from 'react'
import {Autocomplete, GoogleMap} from '@react-google-maps/api'
import { Imoveis } from '../../../../../context/types'
import {useTeseStore } from '../../../../../context/DataContext'
import { latLng } from '../../../../../../../components/util'
import useMap from './useMap'
import useAutocomplete from '../../../../../hooks/useAutocomplete'



type Props = {
  height: number
  imoveis: Imoveis[]
}
const centerInit = {
  lat: -23.56834983242366,
  lng: -46.65739491734664,
}
// type Showhide = {
//   show: boolean
//   id: number
// }

const MapView: React.FC<Props> = ({height, imoveis}) => {
  const {endereco} = useTeseStore()
  const [center, setCenter] = useState<latLng>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [show, setShow] = useState<Showhide>({
  //   show: false,
  //   id: null,
  // })
  // const markerRef = useRef<Marker>(null)
  const {onLoad, onUnmount, map} =
    useMap()
  const {
    setMarcador,
    onPlaceChanged,
    buscaPosition,
    onLoadAutoComplete
  } = useAutocomplete(map)

  useEffect(() => {
    if (endereco) {
      setCenter({
        lat: endereco.latitude,
        lng: endereco.longitude,
      })
    } else {
      setCenter(centerInit)
    }
  }, [endereco])

  // function onchangeAdress(map, enderecoFormatado: Endereco) {
  //   if (map) {
  //     setMarcadorAddress(map, enderecoFormatado)
  //     setEndereco({...enderecoFormatado})
  //   }
  // }
  useEffect(() => {
    if (endereco) {
      setMarcador([
        {
          lat: endereco.latitude,
          lng: endereco.longitude,
          endereco: endereco.endereco_completo,
        },
      ])
    }
  }, [endereco, setMarcador])

  return (
    <>
      {/* <button
          onClick={() => {
            onchangeAdress(map, {
              latitude: -23.5986377,
              longitude: -46.6689923,
              endereco_completo: 'R. Diogo Jácome, 818 - Moema, São Paulo - SP, 04512-001, Brasil',
              logradouro: 'Rua Diogo Jácome',
              numero: '818',
              bairro: 'Moema',
              cidade: 'São Paulo',
              estado: 'SP',
              cep: '04512-001',
            })
            setBuscaPosition('')
          }}
        >
          Load Bairros
        </button> */}

      <GoogleMap
        mapContainerStyle={{width: '100%', height: height}}
        center={center}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          // mapId: 'a28cbce2ae82f5e3',
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              'roadmap',
              'satellite',
              'hybrid',
              'noturno',
              'comercios_map',
              'onibus_map',
              'ligth',
              'simples',
              // 'a28cbce2ae82f5e3',
            ],
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.LEFT_TOP,
          },
        }}
      >
        <div className={buscaPosition}>
          <Autocomplete
            onLoad={onLoadAutoComplete}
            onPlaceChanged={() => {
              onPlaceChanged(map)
            }}
          >
            <input
              type='text'
              placeholder='Digite um endereço...'
              className='form-control'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `48%`,
                height: `32px`,
                padding: `18px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '200px',
                top: '11px',
              }}
            />
          </Autocomplete>
        </div>
       
      </GoogleMap>
    </>
  )
}

export default MapView
