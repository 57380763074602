import {Col, Tab} from 'react-bootstrap'
import {useState} from 'react'
import {TabItens} from './TabsItens'
import Mapa from './TabMapa/Mapa'
import {TabTese} from './TabTese'

export function Tabs({windowHeight}) {
  const [activeTab, setActiveTab] = useState('tese')
console.log(activeTab)
  return (
    <div className='wrapper d-flex flex-column flex-row-fluid'>
      <div
        className='content p-0 d-flex flex-column flex-column-fluid'
      >
        <div className='container-fluid p-0 border-1 border-radius'>
          <div className='' style={{height: windowHeight.h}}>
            <Tab.Container id='left-tabs-example' defaultActiveKey='item0'>
              <div style={{display: 'flex', height: windowHeight.h}}>
                <Col
                  sm={3}
                  md={3}
                  xl={3}
                  xls={3}
                  className='d-flex flex-column justify-content-between p-0'
                  style={{
                    width: '243px',
                    backgroundColor: '#fff',
                  }}
                >
                  <TabItens windowHeight={windowHeight.h} setActiveTab={setActiveTab}></TabItens>
                  <div className='dados-position'>{/* <ModalDados></ModalDados> */}</div>
                </Col>
                <Col
                  sm={9}
                  className='p-0 '
                  style={{
                    width: windowHeight.w,
                    borderLeft: '1px solid #d0d0d0',
                    height: windowHeight.h,
                    overflowY: 'auto',
                  }}
                >
                  <Tab.Content>
                    <Tab.Pane eventKey='item0'>
                      <TabTese height={0}></TabTese>
                    </Tab.Pane>

                    <Tab.Pane eventKey='item1'>
                      <Mapa  />
                    </Tab.Pane>

                    <Tab.Pane eventKey='item3'>
                      {/* <Estatisticas></Estatisticas> */}
                      {/* {activeTab === 'resumoMercado' ? <ResumoMercado></ResumoMercado> : <></>} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item4'>
                      {/* {activeTab === 'performance' ? <Performance></Performance> : <></>} */}
                      {/* <Performance></Performance> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item41'>
                      {/* <PerformanceSocio></PerformanceSocio> */}
                      {/* {activeTab === 'sociodemografico' ? (
                  <PerformanceSocioDemografico></PerformanceSocioDemografico>
                ) : (
                  <></>
                )} */}
                      {/* <PerformanceSocioDemografico></PerformanceSocioDemografico> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item7'>
                      {/* {activeTab === 'mapas' ? <Mapas height={windowHeight.h}></Mapas> : <></>} */}
                      {/* <PerformanceResumoMercado></PerformanceResumoMercado> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item43'>
                      {/* {activeTab === 'performanceAnaliseConcorrentes' ? (
                  <PerformanceAnaliseConcorrentes></PerformanceAnaliseConcorrentes>
                ) : (
                  <></>
                )} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item49'>
                      {/* {activeTab === 'desempenhovendas' ? (
                  <PerformanceDesempenhoVendas></PerformanceDesempenhoVendas>
                ) : (
                  <></>
                )} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item44'>
                      {/* {activeTab === 'performanceVendas' ? (
                  <PerformanceVendas></PerformanceVendas>
                ) : (
                  <></>
                )} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item45'>
                      {/* <PerformanceVendas></PerformanceVendas> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item46'>
                      {/* {activeTab === 'performanceBrevesLancamentos' ? (
                  <PerformanceBrevesLancamentos></PerformanceBrevesLancamentos>
                ) : (
                  <></>
                )} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item47'>
                      {/* {activeTab === 'mercadoRevenda' ? (
                  <PerformanceMercadoRevenda></PerformanceMercadoRevenda>
                ) : (
                  <></>
                )} */}
                      {/* <PerformanceMercadoRevenda></PerformanceMercadoRevenda> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item48'>
                      {/* {activeTab === 'pontosInteresse' ? (
                  <PerformancePontosInteresse></PerformancePontosInteresse>
                ) : (
                  <></>
                )} */}
                    </Tab.Pane>
                    <Tab.Pane eventKey='item5'>
                      {/* {activeTab === 'avm'  ? (
                  <Avm id={Number(idProjetoURL)}></Avm>
                ) : (
                  <></>
                )} */}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  )
}
