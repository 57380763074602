import {create} from 'zustand'
import { CloseLoaderInfoOverlay, LoaderInfoOverlay } from '../../../components/UI/Loader/LoaderOverlay'
import Swal from 'sweetalert2'
import { Endereco, Feature, Filter, Imoveis, Projeto,Bairros } from './types'
import { ApiUrbit } from '../../../../infra/http/urbit'

type StateProps = {
  imoveis: Imoveis[]
  endereco: Endereco
  filtro: Filter
  geometria:Feature
  projeto:Projeto
  bairros: Bairros[]
  geometriaSelecinada:Feature
  computados:number[]
  fetchData: (geom:Feature, areaInfluencia:'RAIO' | 'ZONAVALOR' | 'ISOCRONA' | 'DEFINIRMANUALMENTE' ) => void
  fetchBairro:(idCidade:number) => void
  setEndereco: (endereco: Endereco) => void
  setFiltro: (filtro: Filter) => void
  setGeometria: (geometria: Feature) => void
  setGeometriaSelecionada: (geometriaSelecinada: Feature) => void
  add: (id: number) => void
  remove: (id: number) => void
  clearData: () => void
  updateData: (imoveis:Imoveis[]) => void
  setProjeto: (projeto: Projeto) => void
  getComputados: () => number[],
  setComputados: (ids:number[]) => void
  filtrarImoveis: (imoveis:Imoveis) => Imoveis
}

export const useTeseStore = create<StateProps>((set,get) => ({
  imoveis: [],
  endereco: null,
  filtro:{
    tese:null,
    idEstado:null,
    nomeEstado:null,
    idCidade:null,
    nomeCidade:null,
    idBairro:null,
    bairro:null,
    zonaUso:null,
    area: null,
    areaMinimaTerreno: null,
    areaMaximaTerreno: null,
    areaMinimaLote:null,
    pavimentos:null,
    tipoUso:null,
    proprietarioUnico:null,
  },
  geometria:null,
  geometriaSelecinada:null,
  computados:null,
  bairros:null,
  projeto:null,
  fetchBairro:async(idCidade)=>{

    if (idCidade){
      LoaderInfoOverlay('Carregando Bairros...')
      // const  url= `bairros?idCidade=${idCidade}`
      const bairros =[] // await ApiUrbit.get(url)

      console.log(bairros)
      
      set((state)=>({bairros: bairros}))
      CloseLoaderInfoOverlay()
      // return [{
      //   id:1,
      //   nome:'teste',
      //   geom:null
      // }]
    }
  },
  fetchData: async (geom:Feature, areaInfluencia ) => {

    LoaderInfoOverlay('Carregando imóveis...')
    // const centroid = turf.centroid(geom.geometry)
    // const lat = centroid.geometry.coordinates[1]
    // const lng = centroid.geometry.coordinates[0]

    if (!geom) {
      Swal.fire(
        '',
        'Erro ao tentar carregar um geometria, tente aplicar o filtro novamente.',
        'warning'
      )
      CloseLoaderInfoOverlay()
    }
    // let geomSerach = null
    // if (geom.type === 'Feature') {
    //     geomSerach = geom.geometry
    // }

    // const intersectString = btoa(unescape(encodeURIComponent(JSON.stringify(geomSerach))))
    // const tipo = get().filtro.tipologia.join(',')
    // let url = `service/apartamentos-ofertas/${lng}/${lat}?distance=800&intersect=${intersectString}`
    // let url = `service/anuncios-venda/${lng}/${lat}?distance=800&intersect=${intersectString}`
    // let url = `anuncios-usados?tipo=${tipo}&finalidade=venda&intersect=${intersectString}`
    // const imoveis = await ApiUrbit.get(url)

    // https://api.urbit.com.br/listing


    // let imoveisAjustados = imoveis.itens.map((item:Imoveis)=>{
    //     item.geom =  JSON.parse(item.geom+'')
    //     item.vagas = Number(item.vagas)
    //     item.suites = Number(item.suites)
    //     item.quartos = Number(item.quartos)
    //     item.banheiros = Number(item.banheiros)
    //     item.active = true
    //     item.intersect = true
    //     item.valor = isNaN(Number(item.valor)) ? 0 :  Number(item.valor)
    //     item.preco_m2 = item.valor /Number(item.area)
    //     item =  get().filtrarImoveis(item)
    //     return item 
    // })
    // let newData = imoveisAjustados.map((item: any) => {
    //     item = get().filtrarImoveis(item)
    //     return item
    // })
    
    // set({imoveis: imoveisAjustados})
    CloseLoaderInfoOverlay()
  },
  setEndereco: (endereco) => set((state) => ({endereco: endereco})),
  setFiltro: (filtro: Filter) => {
    set((state) => ({filtro: filtro}))
    let newData = get().imoveis.map((item: any) => {
        item = get().filtrarImoveis(item)
        return item
    })
    set((state) => ({imoveis: newData}))
  },
  filtrarImoveis:(item)=>{
    // let filtroDormitorios = true
    let filtro = get().filtro
      if (filtro){
      // if (filtro?.dormitorios?.length > 0) {
      //   if (filtro.dormitorios.indexOf(Number(item.quartos)) !== -1) {
      //     filtroDormitorios = true
      //   } else {
      //     filtroDormitorios = false
      //   }
      // }
      // let filtroVagas = true
      // if (filtro?.vagas?.length > 0) {
      //   if (filtro.vagas.indexOf(Number(item.vagas)) !== -1) {
      //     filtroVagas = true
      //   } else {
      //     filtroVagas = false
      //   }
      // }

      // let filtroValor = true
      // if (filtro?.faixaTicket) {
      //   if (item.valor >= filtro.faixaTicket[0] && item.valor <= filtro.faixaTicket[1]) {
      //     filtroValor = true
      //   } else {
      //     filtroValor = false
      //   }
      // }
      // let filtroPrecoM2 = true
      // if (filtro?.precoMedioM2) {
      //   if (item.preco_m2 >= filtro.precoMedioM2[0] && item.preco_m2 <= filtro.precoMedioM2[1]) {
      //     filtroPrecoM2 = true
      //   } else {
      //     filtroPrecoM2 = false
      //   }
      // }

      // let filtroArea = true
      // if (filtro?.faixaMetragem) {
      //   if (item.area >= filtro.faixaMetragem[0] && item.area <= filtro.faixaMetragem[1]) {
      //     filtroArea = true
      //   } else {
      //     filtroArea = false
      //   }
      // }

      // let filtroDistancia = true
      // if (filtro?.distancia) {
      //   if (item.distancia >= filtro.distancia[0] && item.distancia <= filtro.distancia[1]) {
      //     filtroDistancia = true
      //   } else {
      //     filtroDistancia = false
      //   }
      // }

      // let filtroTipologia = true
      // if (filtro.tipologia?.length > 0) {
      //   if (filtro.tipologia.includes(item.tipo)) {
      //     filtroTipologia = true
      //   } else {
      //     filtroTipologia = false
      //   }
      // }

      // console.log('filtroDormitorios', '-', filtroDormitorios)
      // console.log('filtroVagas', '-', filtroVagas)
      // console.log('filtroValor', '-', filtroValor)
      // console.log('filtroPrecoM2', '-', filtroPrecoM2)
      // console.log('filtroArea', '-', filtroArea)
      // console.log('filtroDistancia', '-', filtroDistancia)
      // console.log('filtroTipologia', '-', filtroTipologia)
      // if (
      //   filtroDormitorios &&
      //   filtroVagas &&
      //   filtroValor &&
      //   filtroPrecoM2 &&
      //   filtroArea &&
      //   filtroDistancia &&
      //   filtroTipologia
      // ) {
      //   item.active = true
      // } else {
      //   item.active = false
      // }
      return item
    }else{
      return item
    }
  },
  setGeometria: (geometria) => set((state) => ({geometria: geometria})),
  setGeometriaSelecionada: (geometriaSelecinada:Feature) => set(() => ({geometriaSelecinada: geometriaSelecinada})),
  add:(id:number)=>set((state)=>( { imoveis: [...state.imoveis.map(  (item)=>{ if (item.id === id) { item.active = true } ;return item}   )]})),
  remove:(id:number)=>set((state)=>( { imoveis: [...state.imoveis.map(  (item)=>{ if (item.id === id) { item.active = false } ;return item}   )]})),
  clearData:()=>set((state)=>( { imoveis: []})),
  updateData: (imoveis:Imoveis[])=>  set({imoveis: imoveis}),
  setProjeto: (projeto:Projeto)=>  set({projeto: projeto}),
  getComputados: ()=>  {
    let filtrado = get().imoveis.filter( (item)=> {
        return item.active 
    })
    return filtrado.map((item)=>item.id)
  },
  setComputados:(ids:number[])=>{
    set((state)=>({computados: ids}))
  }
  
}))
