import { ApiUrbit } from '../../../../../../../../infra/http/urbit'
import {BoxGaugue} from './BoxGaugue'
import {useEffect, useState} from 'react'


interface Props {
  tipo_saida: string
  chart_type: string
  servico: any
  latLng: google.maps.LatLng
}

function getStyle(result, key) {
  let classStyle: string
  if (result['itens'].length > 0) {
    let valor = Number(result['itens'][0][key])

    if (valor <= 50) {
      classStyle = 'danger'
    } else if (valor <= 75) {
      classStyle = 'warning'
    } else if (valor <= 100) {
      classStyle = 'success'
    } else {
      classStyle = 'info'
    }
    return classStyle
  } else {
    return false
  }
}

function getStyleInverse(result, key) {
  let classStyle: string
  if (result['itens'].length > 0) {
    let valor = Number(result['itens'][0][key])

    if (valor <= 25) {
      classStyle = 'success'
    } else if (valor <= 50) {
      classStyle = 'warning'
    } else {
      classStyle = 'danger'
    }
    return classStyle
  } else {
    return false
  }
}

function getValor(result, key) {
  return Number(result['itens'][0][key])
}
export const RenderComponent: React.FC<Props> = ({tipo_saida, chart_type, servico, latLng}) => {
  const lat = latLng.lat()
  const lng = latLng.lng()
  const [result, setResult] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const data = await ApiUrbit.getServico(
        servico.url_api,
        lat,
        lng,
        'sao-paulo',
        servico.distancia
      )
      if (data) {
        setResult(data)
      }
    }
    fetchData()
    return () => {
      setResult(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, servico.distancia, servico.url_api])

  if (!result) {
    return <div>Carregando</div>
  } else {
    if (typeof result['itens'] !== 'undefined') {
      if (result['itens'].length > 0) {
        let valor = null
        let style = null
        style =
          servico.id === 45
            ? getStyleInverse(result, servico.chart_column_x)
            : getStyle(result, servico.chart_column_x)
        valor = getValor(result, servico.chart_column_x)

        return (
          <BoxGaugue
            className='card-xl-stretch mb-xl-8'
            chartColor={style}
            chartHeight='150px'
            titulo={servico.titulo}
            valor={valor}
            descricaoCurta={servico.descricao}
          />
        )
      } else {
        return <></>
      }
    }
  }
}
