import {zodResolver} from '@hookform/resolvers/zod'
import {useEffect, useRef, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {Link, useHistory, useParams} from 'react-router-dom'
import {
  RealEstateLaunchFormData,
  RealEstateLaunchSchema,
} from './partials/typologies/formValidations'
import {RealEstateLaunch} from '../../../../services/urbit-gis/types'
import {useEmpreendimentos} from './context/useEmpreendimentos'
import {CardHeaderNavigation} from './partials/CardHeader'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {TabInfos} from './components/TabInfos'
import {TabHistory} from './components/TabHistory'
import {TabMedia} from './components/TabMedia'
import {addLaunch, updateLaunch} from '../../../../services/urbit-gis'
import Swal from 'sweetalert2'
import { TabDoc } from './components/TabDoc'

export function Empreendimentos() {
  const {id: idURL} = useParams<{id: string}>()
  const {launch, fetchLaunchByID} = useEmpreendimentos()
  const [loading, setLoading] = useState(true)
  const formRef = useRef<HTMLFormElement>(null) // Adicione esta linha
  const navigate = useHistory()

  const methods = useForm<RealEstateLaunchFormData>({
    resolver: zodResolver(RealEstateLaunchSchema),
  })
  const {reset} = methods

  useEffect(() => {
    const fetchLaunch = async () => {
      if (!idURL) {
        // Se idURL não existir, não execute a busca e defina o carregamento como falso
        setLoading(false)
        return
      }

      try {
        await fetchLaunchByID(parseInt(idURL))
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar lançamento:', error)
        setLoading(false)
      }
    }

    fetchLaunch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idURL])

  useEffect(() => {
    if (launch) {
      reset({
          ...launch,
          longitude: launch.geom.coordinates[0], // Acesse a propriedade correta dentro de realEstateLaunch
          latitude: launch.geom.coordinates[1],
      })
    }
  }, [launch, reset])

  const onSubmit = async (data: RealEstateLaunchFormData) => {
    try {

      console.log(data)
      let result
      let message

      // Mostrar Swal de loading
      Swal.fire({
        title: 'Enviando Informações...',
        text: 'Por favor, aguarde.',
        icon: 'info',
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

      if (idURL) {
        // Atualiza o empreendimento
        result = await updateLaunch(parseInt(idURL), data)
        message =
          result.service !== 'error'
            ? 'Empreendimento atualizado com sucesso.'
            : result.message || 'Houve um erro ao atualizar o empreendimento.'
      } else {
        // Adiciona o empreendimento
        result = await addLaunch(data)
        message =
          result.service !== 'error'
            ? 'Empreendimento adicionado com sucesso.'
            : result.message || 'Houve um erro ao adicionar o empreendimento.'
      }

      // Atualiza a mensagem do Swal após a requisição
      Swal.fire({
        title: result.service !== 'error' ? 'Sucesso!' : 'Erro!',
        text: message,
        icon: result.service !== 'error' ? 'success' : 'error',
        confirmButtonText: 'OK',
      }).then(() => {
        // Redireciona apenas se o método foi `addLaunch` e há um `id`
        const id = result.data?.id
        if (!idURL && id) {
          navigate.push(`/backoffice/empreendimentos/edit/${id}`)
        }
      })
    } catch (error) {
      // Fechar Swal de loading em caso de erro e mostrar erro
      Swal.fire({
        title: 'Erro!',
        text: 'Houve um erro ao salvar o empreendimento.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const handleExternalSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit() // Dispara o envio do formulário
    }
  }

  if (loading) return <p>Loading...</p>

  return (
    <>
      <div
        className='py-6 d-flex align-items-center justify-content-between'
        id='kt_header_container'
      >
        <div className='w-100 d-flex align-items-center justify-content-between'>
          <div className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'>
            <h1 className='text-dark fw-bold my-0 fs-2'>Editar</h1>
            <ul className='breadcrumb fw-semibold fs-base my-1'>
              <li className='breadcrumb-item text-muted'>
                <a href='/metronic8/demo7/../demo7/index.html' className='text-muted'>
                  Empreendimentos
                </a>
              </li>
            </ul>
          </div>
          <div>
            <Link to='/backoffice/empreendimentos' className='btn btn-sm btn-secondary me-4'>
              Cancelar
            </Link>
            <button type='button' className='btn btn-sm btn-success' onClick={handleExternalSubmit}>
              Salvar
            </button>{' '}
            {/* Modifiquei o type para 'button' e adicionei onClick */}
          </div>
        </div>
      </div>
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <FormProvider {...methods}>
          <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
            <CardHeaderNavigation data={launch} />
            <div className='inboxContent' style={{width: '100%'}}>
              <div className='row gy-5 gx-xl-8'>
                <div className='col-xl-12'>
                  <div className='tab-content' id='tabContent'>
                    <TabInfos data={launch} />
                    <TabHistory />
                    <TabMedia />
                    <TabDoc />
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </form>
        </FormProvider>
      </div>
    </>
  )
}
