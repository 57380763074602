/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {QueryGetEmpreendimentoSituacao} from '../../../../../graphql/services/EmpreendimentoSituacao'
import {BarChart} from './BarChart'
import {QueryGetEmpreendimentosRelatorioStatus, QueryGetEquipe} from '../../../../../graphql'
import {QueryGetEquipes} from '../../../../../graphql/services/Equipes'
import {ContinuousColorLegend} from 'react-vis'

type Props = {
  className: string
}

const TablePorSituacaoEmpreendimento: React.FC<Props> = ({className}) => {
  const {data: dataStatus} = QueryGetEmpreendimentoSituacao({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  const {data: dataEquipes} = QueryGetEquipes({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  const {data: dataRelatorio} = QueryGetEmpreendimentosRelatorioStatus({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  if (!dataStatus) {
    return <div>'carregando...'</div>
  }
  if (!dataEquipes) {
    return <div>'carregando...'</div>
  }

  if (!dataRelatorio) {
    return <div>'carregando...'</div>
  }
  let dataReport = []

  let equipes = dataEquipes.GetEquipe.result.map((item) => item.nome)
  // let equipes = dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.map((item)=>{})
  console.log(equipes)
  // eslint-disable-next-line array-callback-return
  // dataStatus.GetEmpreendimentoSituacao.result.map((itens) => {
  //   console.log(itens)

  // })

  dataStatus.GetEmpreendimentoSituacao.result.map((situacao) => {
    console.log('Situacao:', situacao.nome)

    // {
    //   name: ' Prospecção Ativa',
    //  e1, e2, e3, e4
    //   data: [44, 55, 41, 10]
    // }
    let dadosFinal = []
    dataEquipes.GetEquipe.result.map((equipe) => {
      let dataR = dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.filter((item) => {
        if (equipe.id === item.id_equipe && situacao.id === item.id_status) {
          return item
        }
      }).map((item) => item.qtde)

      console.log(dataR)
      dadosFinal.push(dataR.length > 0 ? dataR[0] : 0)
      return dataR
    })

    dataReport.push({
      name: situacao.nome,
      data: dadosFinal,
    })

    // console.log(dadosFinal)

    //     equipes.push(equipe.nome)

    //     console.log('Equipe:',equipe.nome)
    //   console.log('situacao:',situacao.nome)

    //   // console.log('dataReport')
    //   // console.log(dataReport)
    //   // eslint-disable-next-line array-callback-return
    //  let dataR =   dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.filter((item)=>{

    //     if (equipe.id === item.id_equipe && situacao.id === item.id_status) {
    //       return item.qtde
    //     }
    //   }).map((item)=>item.qtde)
    //   console.log('dataR')
    //   console.log(dataR)
    // })
  })
  console.log(dataReport)
  //   // eslint-disable-next-line array-callback-return
  //   let dados = dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.map((item)=>{

  //     // item.id_status === itens.id ? {equipe: item.equipe,qtde:item.qtde} : {equipe: item.equipe,qtde:0
  //       // console.log('dados')
  //       // console.log(dados)
  //       dataReport.push({
  //         name: itens.nome,
  //         data: [44, 55, 41, 37, 22, 43, 21]
  //       })

  //     })

  // })
  console.log(dataRelatorio)
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Empreendimentos </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Equipe x Situação</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <BarChart
          className={''}
          title={''}
          subtitle={''}
          legenda={['']}
          data={dataReport}
          onChange={() => {}}
          categorias={equipes}
          series={dataReport}
        ></BarChart>

        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='border-0'>
                <th className='p-0 '></th>
                {dataStatus.GetEmpreendimentoSituacao.result.map((itens) => {
                  return (
                    <th className='p-0 text-center'>
                      {itens.nome}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {dataEquipes.GetEquipe.result.map((equipe) => {
                return (
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {equipe.nome}
                      </a>
                      {/* <span className='text-muted fw-semibold d-block'>5</span> */}
                    </td>
                    {
                      // eslint-disable-next-line array-callback-return
                      dataStatus.GetEmpreendimentoSituacao.result.map((situacao) => {
                        // eslint-disable-next-line array-callback-return
                        return dataRelatorio.GetEmpreendimentosRelatorioStatusEmpreendimento.map(
                          (item) => {
                            if (equipe.id === item.id_equipe && situacao.id === item.id_status) {
                              return (
                                <td className='text-center text-muted fw-semibold'>{item.qtde}</td>
                              )
                            }
                          }
                        )
                      })
                    }
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
        </div>
        {/* end::Table */}
      </div>

      {/* end::Body */}
    </div>
  )
}

export {TablePorSituacaoEmpreendimento}
