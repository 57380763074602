import {useForm} from 'react-hook-form'
import {TeseFormData, TeseFormSchema} from '../schema/tese.schema'
import {zodResolver} from '@hookform/resolvers/zod'
// import { ApiUrbit } from "../../../../infra/http/urbit"

export const useTese = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<TeseFormData>({
    resolver: zodResolver(TeseFormSchema),
  })

//   const [windowHeight, setWindowHeight] = useState()
//   useEffect(() => {
//     const zoneamentos = getZoneamento({
//       url: 'zoneamento',
//       latitude: -46.6,
//       longitude: -23.6,
//       distancia: 1000,
//       municipio: 'sao-paulo',
//     })
//   })

//   function getZoneamentol() {}

  return {
    register,
    handleSubmit,
    setValue,
    errors
  }
}
