import React, { useEffect, useState } from 'react'

import Select from 'react-select'
// import { QueryGetzonaUsos } from '../../../../graphql/services/zonaUsos'
import { FormLabel } from 'react-bootstrap'
import { useZonaUso } from './useZonaUso'


export function GetZonaUsoChosen({
  setValue,
  value
}:{setValue:any, value:any}) {
  let zonaUsosArray = []

  // const [zonaUso, setZonaUso] = useState(null)
  const {zonaUso,setZonaUso,fetchZonaUso} =  useZonaUso()

  useEffect(() => {
    setZonaUso(value)
  }, [value])

  useEffect(()=>{
  
      fetchZonaUso()

  },[])
  

  // console.log(zonaUsos)
  // const { data } = QueryGetzonaUsos({
  //   variables: {
  //     pagination: {
  //       pagina: 0,
  //       quantidade: 100,
  //     }
  //   }
  // })



  if (!zonaUso) {
    return (
      <>
        <div className='form-group'>
        <FormLabel className='form-label'>zonaUsos</FormLabel>
          {/* <label className='col-form-label'>zonaUsos Disponiveis:</label> */}
          <Select
            options={zonaUsosArray}
            onChange={(e) => {
              setZonaUso(e.value)
            }}
            value={zonaUsosArray.filter((e) => e.value)[0]}
            name='id_zonaUso'
            placeholder='zonaUsos Disponiveis'
          />
        </div>
      </>
    )
  }
  zonaUsosArray.push({ value:'', label: '--selecione--' })
  zonaUso.map((options) => {
    zonaUsosArray.push({ value: options.id, label: options.zonaUso })
    return []
  })

  

  return (
    <>
      <div className='form-group'>
      <FormLabel className='form-label'>zonaUsos</FormLabel>
        {/* <label className='col-form-label'>zonaUsos Disponiveis:</label> */}
        <Select isMulti
          value={  zonaUso ? zonaUso : zonaUsosArray.find((e) => e.value === zonaUso) || null}
          options={zonaUsosArray}
          onChange={(itens) => {
            console.log(itens)
            setZonaUso(itens)
            setValue(itens.map(opt=>opt.value))
            // SetValuezonaUso(e.value)
          }}
          name='id_zonaUso'
          placeholder='zonaUsos'
        />
      </div>
    </>
  )
}
