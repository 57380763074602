import { z } from "zod"

export const TeseFormSchema = z.object({
    tese: z.string(),
    idEstado: z.number(),
    idCidade: z.number(),
    idBairro: z.number(),
    zonaDeUso: z.array(z.string()),
    areaMinimaTerreno:z.array(z.number(),z.number()),
    areaMaximaTerreno:z.array(z.number(),z.number()),
    areaMinimaLote:z.number(),
    pavimentos:z.number(),
    tipoUso:z.array(z.string()),
    proprietarioUnico:z.boolean(),
})

export type TeseFormData = z.infer<typeof TeseFormSchema>