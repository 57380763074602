import {useQuery} from '@apollo/client'
import {InputHTMLAttributes,  useState} from 'react'
import Select, { StylesConfig } from 'react-select'
import {GET_IMOVEL_SITUACAO_CLIENTE} from '../../../../graphql/query/imovel-situacao-cliente'
import {TypesGetImovelSituacaoClienteResponse} from '../../../../graphql/type/imovel-situacao-cliente'
import chroma from 'chroma-js'
import { ColourOption } from '../../../../backoffice/pages/empresas/profile/components/Tabs/Tab2/data'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue: number
  register?: any
  error?: any
  onValueChange: any
}

export function GetSituacaoClienteChosen({
  defaultValue,
  register,
  onValueChange,
  error,
  ...props
}: InputProps) {
  
  let options = []
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState('')
  const {data} = useQuery<TypesGetImovelSituacaoClienteResponse>(GET_IMOVEL_SITUACAO_CLIENTE, {
    variables: {
      pagination: {
        quantidade: null,
        pagina: null,
      },
    },
  })

  // useEffect(() => {
  //   onValueChange(status)
  // }, [onValueChange, status])

  // Tratativa de loading
  if (!data) {
    return (
      <Select
        options={options}
        onChange={(e) => {
            setStatus(e.value)
        }}
        isDisabled={false}
        placeholder='Situação do Terreno'
      />
    )
  }
  options.push({value:'', label: '--selecione--'})
  data.GetImovelSituacaoCliente.result.map((Options) => {
    options.push({value: Options.id, label: Options.nome, color:Options.cor})
    return []
  })
  const value = options.filter((e) => {
    return e.value === defaultValue
  })[0]


    // rgba(0, 0, 0, 0.5);
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
    
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });
  
    const colourStyles: StylesConfig<ColourOption> = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color || '#333333' );
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 1.5
              ? 'white'
              : 'black'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
    
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? data.color
                : color.alpha(0.5).css()
              : undefined,
          },
        };
      
      },
      input: (styles) => ({ ...styles, ...dot() }),
      placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };
    

    
  return (
    <Select
      menuPortalTarget={document.body}
      // styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      options={options}
      value={value}
      name='status-cliente'
      onChange={(e) => {
        setStatus(e.value)
        onValueChange(e.value)
      }}
      placeholder='Tipos do Terreno'
      styles={colourStyles}
    />
  )
}
