import React from 'react'
import {
  GoogleMap,
  OverlayView,  
  Marker,
  DrawingManager,
} from '@react-google-maps/api'
import { GeoCoordinates } from '../../../../services/urbit-gis/types'

type PropsContextMenu = {
  latLng: GeoCoordinates
  onChange: any
}

export function CoordinatesToPath(geom) {
  let paths = geom.map(function (el) {
    return {lat: el[1], lng: el[0]}
  })
  return paths
}

export const MapStatic: React.FC<PropsContextMenu> = ({latLng, onChange}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)

  const containerStyle = {
    height: '400px',
  }

  const latLngObj = latLng ? {
    lat: latLng.coordinates[1],
    lng: latLng.coordinates[0],
  } : null

  const center = latLngObj || {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }
  
  const onLoad = React.useCallback(
    function callback(map) {
      const imageMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return [
            process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
            zoom,
            '/',
            coord.x,
            '/',
            coord.y,
            '.png?type=png',
          ].join('')
        },
        tileSize: new google.maps.Size(256, 256),
      })

      map.overlayMapTypes.push(imageMapType)
      const comercios = [
        {
          featureType: 'poi',
          stylers: [{visibility: 'off'}],
        },
      ]
      const comerciosMapType = new google.maps.StyledMapType(comercios, {name: 'Comércios'})
      map.mapTypes.set('comercios_map', comerciosMapType)
      setMap(map)
    },
    [setMap]
  )

  const onUnmount = React.useCallback(
    function callback(map) {
      setMap(null)
    },
    [setMap]
  )

  

  const divStyle = {
    background: 'transparent',
  }

  

  let isLoaded = true
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={19}
      onLoad={onLoad}
      onUnmount={onUnmount}
      key={2}
      options={{
        mapTypeId: 'comercios_map',
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ['comercios_map'],
        },
      }}
    >
      <DrawingManager
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },

          polylineOptions: {
            visible: false,
          },
          markerOptions: {
            visible: false,
          },
          polygonOptions: {
            fillColor: `#20a745`,
            fillOpacity: 0.6,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
      />
       {latLng ? <Marker
          position={latLngObj} 
          draggable
          onDragEnd={(data) => {
            onChange(data)
          }}
        ></Marker> :'' }
      <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div style={divStyle}></div>
      </OverlayView>
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}
