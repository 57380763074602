/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { getCSS } from '../../../../../../_metronic/assets/ts/_utils'
import { formatNumber } from '../../../../../components/util'


// import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
// import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  title: string
  subtitle: string
  legenda: [string]
  tooltip?: string
  data: any
  onChange: any
  categorias:any
  series:any
}

export const BarChart: React.FC<Props> = ({
  title,
  subtitle,
  data,
  className,
  legenda = ['Qtde amostras.'],
  // tooltip=
  onChange,
  children,
  categorias,
  series
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(data, height, onChange, legenda,series,categorias))

    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>{subtitle}</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* {filtroDomitorio ? <SelectDormitorios onChange={onChange}></SelectDormitorios> : <></>} */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '350px',width:'100%'}}></div>
        {/* end::Chart */}
        <div>{children}</div>
      </div>

      {/* end::Body */}
    </div>
  )
}

function getChartOptions(data: [], height: number, onChange: any, legenda,series:any, categorias:any): ApexOptions {
  return {
    series: series,
    chart: {
    type: 'bar',
    height: 350,
    stacked: true,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    },
  },
  stroke: {
    width: 1,
    colors: ['#fff']
  },
  title: {
    // text: 'Fiction Books Sales'
  },
  xaxis: {
    categories: categorias, //;;['Equipe 1', 'Equipe 2', 'Equipe 3'],
    labels: {
      formatter: function (val) {
        return val + ""
      }
    }
  },
  yaxis: {
    title: {
      text: undefined
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + "K"
      }
    }
  },
  fill: {
    opacity: 1
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40
  }
  }
}
