import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'
import {Dicionario} from '../../../../utils/Dicionario'

export const RelatedPeopleEmails = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['RelatedPeopleEmails']
  } else {
    if (typeof data[0]['RelatedPeopleEmails'] !== 'undefined'){
      dateReturn = data[0]['RelatedPeopleEmails']
    }
  }

  return dateReturn ? (
    <>
      <View style={[styles.table, {paddingTop: 15}]}>
        <View style={[styles.row, {paddingTop: 15, paddingBottom: 10}]}>
          <Text style={[styles.subtitulo]}>Pessoas Relacionados E-mails</Text>
        </View>
        <View style={[styles.row, styles.bold, styles.header]}>
          {/* {estrutura['colunsItens'].map((column, index) => {
            return ( */}
          <Text style={styles.rowDefault}>Email</Text>
          <Text style={styles.rowDefault}>Grau Parentesco</Text>

          {/* <Text style={styles.rowDefault} key={index}>
                {column}
              </Text> */}
          {/* )
          })} */}
        </View>
      </View>

      <View>
        {dateReturn?.map((item, index) => {
          return (
            <View key={index} style={styles.row} wrap={false}>
              {/* {estrutura['colunsItens'].map((column, index2) => {
                return ( */}
              <Text style={styles.rowDefault}>{item['EmailAddress']}</Text>
              <Text style={styles.rowDefault}>
                {Dicionario.translate(item['RelationshipType'])}
              </Text>

              {/* <Text style={styles.rowDefault} key={index2}>
                    {item[column]}
                  </Text> */}
              {/* )
              })} */}
            </View>
          )
        })}
      </View>
    </>
  ) : (
    <></>
  )
}
