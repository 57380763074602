import { useState } from 'react'
import { QueryGetEmpresas } from '../../../graphql/services/Empresas';
import PaginationComponent from '../../../../components/UI/PaginationComponents';
import { CardTables } from '../../../../components/UI/CardTables';
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable';
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable';
import { QueryGetUsuarios } from '../../../graphql';
import { DateTimToView } from '../../../../components/util';
import { differenceInDays } from 'date-fns';
type Props = {
    filtro: any[]
    setFiltro: any
}
export function TableAdminAssinaturas({ filtro, setFiltro }: Props) {
    const [paginacao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })
    const [stateSwitch, setStateSwitch] = useState(false)

    function onChange(value: boolean) {
        setStateSwitch(value)
        setFiltro([])
        const inputElement = document.getElementById("form-filter-input") as HTMLInputElement;
        if (inputElement) {
            inputElement.value = "";
        }
    }

    const { data: dataEmpresas, loading: loadingEmpresas, error: errorEmpresas } = QueryGetEmpresas({
        variables: {
            pagination: {
                pagina: paginacao.pagina,
                quantidade: paginacao.quantidade
            },
            filter: {
                fields: stateSwitch === false ? filtro : [],
            },
            situacao: 1,
        }
    });
    const { data: dataUsuarios, loading: loadingUsuarios, error: errorUsuarios } = QueryGetUsuarios({
        variables: {
            pagination: {
                pagina: paginacao.pagina,
                quantidade: paginacao.quantidade,
            },
            filtro: {
                fields: stateSwitch === true ? filtro : [],
            },
        },
    })

    // Função para calcular a diferença em dias entre duas datas
    const calculateDateDifference = (dateA, dateB) => {
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = dateA.getTime() - dateB.getTime();
        return Math.round(differenceInMilliseconds / oneDayInMilliseconds);
    };

    return (
        <>
            <CardTables
                headerTitle={stateSwitch === false ? 'Empresas' : 'Usuários'}
                headerSubtTitle={`
                    Listando ${stateSwitch === false
                        ? dataEmpresas
                            ? dataEmpresas.GetEmpresas.pageInfo.totalItems
                            : 0
                        : dataUsuarios
                            ? dataUsuarios.GetUsuarios.pageInfo.totalItems
                            : 0
                    } assinaturas registradas.`}
                headerToolbar={
                    <div className='card-toolbar'>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${stateSwitch === false ? 'active' : ''
                                        } fw-bold px-4 me-1`}
                                    onClick={() => onChange(false)}
                                >
                                    Empresas
                                </button>
                            </li>
                            <li className='nav-item'>
                                <button
                                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${stateSwitch === true ? 'active' : ''
                                        } fw-bold px-4 me-1`}
                                    onClick={() => onChange(true)}
                                >
                                    Usuários
                                </button>
                            </li>
                        </ul>
                    </div>
                }
                footer={
                    stateSwitch === false ? (
                        dataEmpresas ? (
                            <PaginationComponent
                                pagesInfo={dataEmpresas.GetEmpresas.pageInfo}
                                setPagesInfo={(pagina: number, quantidade: number) => {
                                    setPaginacao({ pagina: pagina, quantidade: quantidade })
                                }}
                            ></PaginationComponent>
                        ) : (
                            <></>
                        )
                    ) : (
                        dataUsuarios ? (
                            <PaginationComponent
                                pagesInfo={dataUsuarios.GetUsuarios.pageInfo}
                                setPagesInfo={(pagina: number, quantidade: number) => {
                                    setPaginacao({ pagina: pagina, quantidade: quantidade })
                                }}
                            ></PaginationComponent>
                        ) : (
                            <></>
                        )
                    )
                }
            >
                <div className='tab-content'>
                    {/* begin::Tap pane */}
                    <div className={`tab-pane fade ${stateSwitch === false ? ' show active' : ''}`} id='assinaturas_tab_1'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className='w-50px'>ID</th>
                                        <th className='w-250px'>Nome da empresa</th>
                                        <th className='min-w-150px'>Produtos Associados</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <LoadingTable loading={loadingEmpresas} />
                                    <ErrorTable error={errorEmpresas} />
                                    {dataEmpresas ? (
                                        dataEmpresas.GetEmpresas.result.length > 0 ? (
                                            dataEmpresas.GetEmpresas.result.map((response) => {
                                                return (
                                                    <tr key={response.id}>
                                                        <td>{response.id}</td>
                                                        <td>
                                                            <a href={`/backoffice/empresas/edit/${response.id}`} target="_blank" rel="noreferrer"><span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                                {response.nome_fantasia}
                                                            </span><br/>
                                                            <small className='text-dark'>{response.nome}</small><br/>
                                                            <small className='text-dark'>{response.cnpj}</small>
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {response.empresa_produto.length > 0 ? (
                                                                <>
                                                                    {[...response.empresa_produto] // Criar uma cópia da array
                                                                        .sort((produtoA, produtoB) => {
                                                                            const dateA = new Date(produtoA.data_fim).getTime();
                                                                            const dateB = new Date(produtoB.data_fim).getTime();
                                                                            return dateA - dateB;
                                                                        })
                                                                        .filter(produto => {
                                                                            const daysDifference = calculateDateDifference(new Date(produto.data_fim), new Date());
                                                                            return daysDifference >= 0; // Filtrar apenas os produtos que não expiraram
                                                                        })
                                                                        .map((produto, index) => {
                                                                            // Calcular a diferença em dias entre a data fim do produto e a data atual
                                                                            const daysDifference = calculateDateDifference(new Date(produto.data_fim), new Date());
                                                                            let badgeClass = '';

                                                                            // Determinar a classe do badge com base na diferença em dias
                                                                            if (daysDifference < 9) {
                                                                                badgeClass = 'badge badge-light-danger';
                                                                            } else if (daysDifference <= 15) {
                                                                                badgeClass = 'badge badge-light-warning';
                                                                            } else {
                                                                                badgeClass = 'badge badge-light-success';
                                                                            }

                                                                            return (
                                                                                <td key={index} className='flex flex-wrap min-w-350px'>
                                                                                    {produto.produto.nome} <span className={badgeClass}>{`${daysDifference} dias restantes`}</span>
                                                                                </td>
                                                                            );
                                                                        })
                                                                        .reduce((rows, cell, index) => {
                                                                            const rowIndex = Math.floor(index / 4);
                                                                            if (!rows[rowIndex]) {
                                                                                rows[rowIndex] = [];
                                                                            }
                                                                            rows[rowIndex].push(cell);
                                                                            return rows;
                                                                        }, [])
                                                                        .map((row, index) => <tr key={index}>{row}</tr>)}
                                                                    {response.empresa_produto.every(produto => calculateDateDifference(new Date(produto.data_fim), new Date()) < 0) && (
                                                                        <span className="badge badge-light-primary">Nenhum produto ativo</span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <span className='badge badge-light-primary'>Nenhum produto associado</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={10} className='text-center'>
                                                    Nenhum registro encontrado
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                    {/* begin::Tap pane */}
                    <div className={`tab-pane fade ${stateSwitch === true ? ' show active' : ''}`} id='assinaturas_tab_2'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className='w-50px'>ID</th>
                                        <th className='min-w-300px'>Nome do Usário</th>
                                        <th className='min-w-140px'>Produtos Associados</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <LoadingTable loading={loadingUsuarios} />
                                    <ErrorTable error={errorUsuarios} />
                                    {dataUsuarios ? (
                                        dataUsuarios.GetUsuarios.result.length > 0 ? (
                                            dataUsuarios.GetUsuarios.result.map((response) => {
                                                return (
                                                    <tr key={response.id}>
                                                        <td>{response.id}</td>
                                                        <td>
                                                            <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                                {response.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {response.user_produto.length > 0 ? (
                                                                <>
                                                                    {[...response.user_produto] // Criar uma cópia da array
                                                                        .sort((produtoA, produtoB) => {
                                                                            const dateA = new Date(produtoA.data_fim).getTime();
                                                                            const dateB = new Date(produtoB.data_fim).getTime();
                                                                            return dateA - dateB;
                                                                        })
                                                                        .filter(produto => {
                                                                            const daysDifference = calculateDateDifference(new Date(produto.data_fim), new Date());
                                                                            return daysDifference >= 0; // Filtrar apenas os produtos que não expiraram
                                                                        })
                                                                        .map((produto, index) => {
                                                                            // Calcular a diferença em dias entre a data fim do produto e a data atual
                                                                            const daysDifference = calculateDateDifference(new Date(produto.data_fim), new Date());
                                                                            let badgeClass = '';

                                                                            // Determinar a classe do badge com base na diferença em dias
                                                                            if (daysDifference < 9) {
                                                                                badgeClass = 'badge badge-light-danger';
                                                                            } else if (daysDifference <= 15) {
                                                                                badgeClass = 'badge badge-light-warning';
                                                                            } else {
                                                                                badgeClass = 'badge badge-light-success';
                                                                            }

                                                                            return (
                                                                                <td key={index} className='flex flex-wrap min-w-350px'>
                                                                                    {produto.produto.nome} <span className={badgeClass}>{`${daysDifference} dias restantes`}</span>
                                                                                </td>
                                                                            );
                                                                        })
                                                                        .reduce((rows, cell, index) => {
                                                                            const rowIndex = Math.floor(index / 4);
                                                                            if (!rows[rowIndex]) {
                                                                                rows[rowIndex] = [];
                                                                            }
                                                                            rows[rowIndex].push(cell);
                                                                            return rows;
                                                                        }, [])
                                                                        .map((row, index) => <tr key={index}>{row}</tr>)}
                                                                    {response.user_produto.every(produto => calculateDateDifference(new Date(produto.data_fim), new Date()) < 0) && (
                                                                        <span className="badge badge-light-primary">Nenhum produto ativo</span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <span className='badge badge-light-primary'>Nenhum produto associado</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={10} className='text-center'>
                                                    Nenhum registro encontrado
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table */}
                    </div>
                    {/* end::Tap pane */}
                </div>
            </CardTables >
        </>
    )
}