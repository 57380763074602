import React, {useEffect, useState} from 'react'
import {TableDetalhes} from './TableDetalhes'
import {Accordion} from 'react-bootstrap'
import { ApiUrbit } from '../../../../../../../../../infra/http/urbit'


type Props = {
  titulo: string
  latLng: google.maps.LatLng
  itens: {
    nome: string
    descricao: string
    icone: string
    valor: {
      itens: []
      service: string
    }
    url: string
  }[]
}

const ListItens: React.FC<Props> = ({titulo, itens, latLng}) => {
  const [result, setResult] = useState([])
  // const [show, setShow] = useState(false)
  const lat = latLng.lat()
  const lng = latLng.lng()

  useEffect(() => {
    async function fetchData(itens) {
      let itensTemp = [...itens]
      let data = null
      for (const item of itensTemp) {
        if (item.url !== 'faccoes-milicias') {
          data = await ApiUrbit.getServico(
            item.url,
            lat,
            lng,
            'sao-paulo',
            item.distancia ? item.distancia : '500'
          )
          if (typeof data['itens'] !== 'undefined') {
            item.valor = data['itens']
          }
        }
      }
      setResult(itensTemp)
    }
    fetchData(itens)

    return () => {
      setResult([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLng, itens])

  return (
    <>
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>{titulo}</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Latest tech trends</span> */}
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown /> */}
          </div>
        </div>
        <div className='card-body pt-5'>
          <Accordion defaultActiveKey='0'>
            {result.length > 0
              ? result.map(function (result, index) {
                  return (
                    <Accordion.Item eventKey={result + index}>
                      <Accordion.Header>
                        {result.valor.length > 0 ? (
                          <span className='badge badge-success fw-bold my-2 me-3'>Sim</span>
                        ) : (
                          <span className='badge badge-warning fw-bold my-2 me-3'>Não</span>
                        )}{' '}
                        {result.nome}
                      </Accordion.Header>
                      <Accordion.Body>
                        {result.valor.length > 0 ? (
                          <TableDetalhes itens={result}></TableDetalhes>
                        ) : (
                          'Nenhum registro'
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })
              : ''}
          </Accordion>
        </div>
      </div>
    </>
  )
}

export {ListItens}
