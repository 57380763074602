/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {QueryGetNewsFeed} from '../../../graphql/services/NewsFeed'


type Props = {
  className: string
}

const NewsFeed: React.FC<Props> = ({className}) => {
  useEffect(() => {
    async function getApi() {
      // const result = await ApiUrbit.getServico('lote-fiscal', -23.6, -46.6, 'sao-paulo', 0)
      // console.log(result)
      // return result
      // const result = await ApiUrbit.get(`municipio/sao-paulo/lote-fiscal/-46.6/-23.6?distancia=300`)
      // console.log(result)
      // return result
    }
    getApi()
  }, [])

  const {data} = QueryGetNewsFeed({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 5,
      },
    },
  })

  if (!data) {
    return (
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header align-items-center border-0 mt-4'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold mb-2 text-dark'>NewsFeed</span>
            <span className='text-muted fw-semibold fs-7'>Buscas</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-5'>
          {/* begin::Timeline */}
          <div className='timeline-label'>
            {/* begin::Item */}
            <div className='timeline-item'>
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              {/* end::Badge */}
              {/* begin::Text */}
              <div className='fw-mormal timeline-content text-muted ps-3'>
                Nenhuma noticia nova.
              </div>
              {/* end::Text */}
            </div>
            {/* end::Item */}
          </div>
          {/* end::Timeline */}
        </div>
        {/* end: Card Body */}
      </div>
    )
  }

  return (
    <div className={`card  ${className}`}>
      <h1 className='card-title pt-8 p-8 '>Últimas Notícias</h1>
      <div className='card-body pt-0'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {data.GetNewsFeed.result.map((newsFeed, item) => {
            return (
              <div className='timeline-item' key={item}>
                {/* begin::Label */}
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-warning fs-1'></i>
                </div>
                {/* end::Badge */}
                <div className='all-start-column'>
                  {/* begin::Text */}
                  <div className='fw-bold timeline-content title fs-6 ps-3'>{newsFeed.title}</div>
                  {/* end::Text */}
                  {/* begin::Text */}
                  <div className='fw-mormal text-justify   text-muted ps-3'>
                    {newsFeed.description}
                  </div>
                  {/* end::Text */}
                </div>
              </div>
            )
          })}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {NewsFeed}
