import uuid from 'react-uuid'
import {createLayer} from '../Map/Layer/Layers'
import {DEFAULT_COLOR, DEFAULT_HIGHLIGHT_COLOR} from './Layer/Layers/constants/layers'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
import * as turf from '@turf/turf'
// import { Popup } from './Tooltip/GoogleMapsTooltip'

// type Explorer = {
//   map:any
//   deckgl:any
//   draw: any
//   layers: []
//   onUpdateLayer: any
//   onAddLayer: any
//   explorerMapDraw: any
//   positionClick: {
//     lat: number,
//     lng: number
//   },
//   init:any
//   setDraw:any
//   setMap:any
//   setDeckgl:any
//   setDrawMode:any
//   clearDrawMode:any
//   changeMapType:any
//   changeVisibility:any
// }

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Explorer = {
  map: GoogleMapsOverlay,
  deckgl: GoogleMapsOverlay,
  draw: {},
  measure: false,
  measureClickCount: 0,
  layers: [],
  onUpdateLayer: null,
  onAddLayer: null,
  explorerMapDraw: null,
  goTo: {},
  positionClick: {
    lat: null,
    lng: null,
  },
  setMeasure: (measure) => {
    Explorer.measure = measure
  },
  init: () => {},
  setDraw: (draw) => {
    Explorer.draw = draw
  },
  setMap: (map) => {
    Explorer.map = map
  },
  setDeckgl: (deckgl) => {
    Explorer.deckgl = deckgl
  },
  setDrawMode: (type: string) => {
    if (typeof Explorer.draw['setDrawingMode'] !== 'undefined') {
      Explorer.draw['setDrawingMode'](type)
    }
  },
  setGoTo: (lat, lng) => {
    Explorer.goTo = {
      lat: lat,
      lng: lng,
    }
  },
  getGoTo: () => {
    return Explorer.goTo
  },
  clearDrawMode: () => {
    document.querySelectorAll('.btn-action-map').forEach(function (x) {
      x.classList.remove('active')
    })
    Explorer.draw['setDrawingMode']('')
  },
  changeMapType: (id) => {
    if (Explorer.map) {
      Explorer.map['setMapTypeId'](id)
    }
  },
  changeVisibility: (id: any, visible = true) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x) {
      if (id === x.id) {
        x['visible'] = visible
      }
      NewLayer.push(x)
    })
    Explorer.layers = NewLayer
    Explorer.onUpdateLayer(Explorer.layers)
    return Explorer.layers
  },
  getLayers: () => {
    return Explorer.layers
  },

  getCountLayers: () => {
    return Explorer.layers.length
  },
  changeOpacity: (id: any, value: number = 1) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x) {
      if (id === x.map[0].id) {
        x['opacity'] = value
        x.map = x['opacity']
      }
      NewLayer.push(x)
    })
    Explorer.layers = NewLayer
    // Explorer.renderLayers()
    // Explorer.onUpdateLayer(Explorer.layers)
    return Explorer.layers
  },
  changePosition: (laryesPositions: any) => {
    // let NewLayer = []
    const LayerNewPosition = []
    Explorer.layers.forEach(function (item) {

      let uuid = item.id
      // eslint-disable-next-line array-callback-return
      let lfinder = laryesPositions.findIndex((el)=>{
        if (el.id !== 'undefined'){
          return el.id === uuid
        }
       })
       item.position = lfinder
       LayerNewPosition[lfinder] = item
    //   if (id === x.map[0].id) {
    //     x['opacity'] = value
    //     x.map = x['opacity']
    //   }
    //   NewLayer.push(x)

  
    })
    Explorer.layers = LayerNewPosition
    Explorer.onUpdateLayer(Explorer.layers)

    // Explorer.layers = NewLayer
    // Explorer.renderLayers()
    // Explorer.onUpdateLayer(Explorer.layers)
    return Explorer.layers
  },

  setClickPolygon: (id: any, value: number = 1) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      if (id === x.id) {
        if (x.selectedPolygon.length > 0) {
          if (x.selectedPolygon.indexOf(value) !== -1) {
          } else {
            x.selectedPolygon.push(value)
          }
        } else {
          x.selectedPolygon.push(value)
        }
      }
      NewLayer.push(x)
    })
    Explorer.onUpdateLayer(NewLayer)
  },
  removeClickPolygon: (id: any, value: number = 1) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      if (id === x.id) {
        console.log(x.selectedPolygon)
        if (x.selectedPolygon)  {
          if (x.selectedPolygon.length > 0) {
            let idx = x.selectedPolygon.indexOf(value)
            x.selectedPolygon.splice(idx, 1)
          }
        }
      }
      NewLayer.push(x)
    })
    Explorer.onUpdateLayer(NewLayer)
  },

  clearClickPolygon: () => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      x.selectedPolygon = []
      NewLayer.push(x)
    })
    Explorer.onUpdateLayer(NewLayer)
  },

  renderLayers: (layers) => {
    var mapLayer = layers.map(function (layer) {
      var layerCreated = Explorer.create(layer, layer.nome)
      return layerCreated
    })
    mapLayer.push(Explorer.defaultLayers())
    if (Object.keys(Explorer.map).length > 0) {
      Explorer.deckgl.setProps({layers: mapLayer.reverse()})
    }
  },
  defaultLayers: function () {
    const layer = {
      id: 'municipio',
      tile: `${process.env.REACT_APP_MAP_VECTORTILE}/tile/municipios/{z}/{x}/{y}.pbf?s=nacional`,
      style: JSON.parse('{"fill-color":"#ffffff","fill-opacity": 0,"stroke-color":"#d0d0d0" }'),
      visible: true,
      label: '',
      filter: {},
      data: '',
      distance: null,
      intersectId: null,
      intersect: null,
      operation: null,
      selectedPolygon: [],
      composite: [],
      color: [0, 0, 0, 0],
      highlightColor: [0, 0, 0, 0],
      colorField: '',
      type: 'MVTLayer',
      paint: {
        'fill-color': [255, 255, 255, 0],
        opacity: 0.1,
        'line-color': [0, 0, 0],
      },
    }
    var municipio = Explorer.create(layer, '')
    return municipio
  },

  addSingleLayer: function (layer) {
    // let newLayer = data?.GetExplorerLayersUsuario.filter(function (l) {
    //   return l.id === id
    // })
    // let layer = {...newLayer[0]}
    // layer['uuid'] = uuid()
    // layer['visible'] = true
    // // layer['type'] = 'VectorLayer'
    // layer[
    //   'tile'
    // ] = `https://tile.urbit.com.br/tile/${newLayer[0].source}/{z}/{x}/{y}.pbf?s=${newLayer[0].schema_table}`
    // layer['paint'] = {...newLayer[0].paint}
    // const label = Explorer._getLabel(layer['explorer_tiles'])
    Explorer.addLayerTile(layer, '')
    // var municipio = Explorer.create(layer)
    return true
  },

  _getLabel: function (fields) {
    if (fields) {
      let fieldFilter = fields.filter(function (x) {
        return x.label !== null
      })
      if (fieldFilter.length > 0) {
        return fieldFilter[0].label
      } else {
        return false
      }
    } else {
      return false
    }
  },
  getFields: (id: any) => {
    const layers = Explorer.layers
    // eslint-disable-next-line array-callback-return
    const filtered = layers.filter((layer, index) => {
      if (layer.id === id) {
        return layer['explorer_tiles']
      }
    })

    if (filtered.length > 0) {
      return filtered[0]['explorer_tiles']
    } else {
      return []
    }
  },
  changeFilter: (id: any, key: any, value: any, type_data: any) => {
    let NewLayer = []

    Explorer.layers.forEach(function (x) {
      if (id === x.id) {
        if (typeof x.filter === 'undefined') {
          x.filter = {}
        }

        if (typeof x.filter[type_data] === 'undefined') {
          x.filter[type_data] = {}
        }
        if (typeof x.filter[type_data][key] === 'undefined') {
          x.filter[type_data][key] = []
        }

        if (typeof value === 'object') {
          if (value.length > 0) {
            if (!x.filter[type_data][key].includes(value)) {
              x.filter[type_data][key] = value
            }
          } else {
            delete x.filter[type_data][key]
          }
        } else if (value !== '') {
          x.filter[type_data][key] = value
        } else {
          delete x.filter[type_data][key]
        }
      }
      NewLayer.push(x)
    })
    // Explorer.layers = NewLayer
    // Explorer.renderLayers()
    Explorer.onUpdateLayer(NewLayer)
  },
  changeStyle: (id: string, type: string, value: any) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x) {
      if (id === x.id) {
        x.paint[type] = value
      }
      NewLayer.push(x)
    })
    // Explorer.layers = NewLayer
    Explorer.onUpdateLayer(NewLayer)
    // Explorer.onUpdateLayer(Explorer.layers)
  },

  changeNameLayer: (id: String, name: String) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x) {
      if (id === x.id) {
        x.nome = name
        x.label = name
      }
      NewLayer.push(x)
    })
    // Explorer.layers = NewLayer
    // Explorer.renderLayers()
    Explorer.onUpdateLayer(NewLayer)
  },

  getInfoLayer: (id): object => {
    return Explorer.layers.filter(function (x, index) {
      return x.id === id
    })[0]
  },
  getInfoLayerLayerPolygon: (id): object => {
    let layersPolygon = Explorer.layers.filter(function (x, index) {
      return x.type === 'LayerPolygon'
    })
    return layersPolygon[layersPolygon.length - 1]
  },
  removeLayer: (id: String) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      if (id === x.id) {
        if (typeof x.tooltip !== 'undefined') {
          if (Object.entries(x.tooltip).length > 0) {
            x.tooltip.setMap(null)
          }
        }
      }

      if (id !== x.id && id !== x.parentId) {
        NewLayer.push(x)
      }
      if (id === x.parentId) {
        if (Object.entries(x.tooltip).length > 0) {
          x.tooltip.setMap(null)
        }
      }
    })
    Explorer.onUpdateLayer(NewLayer)
  },

  removeLayerByParentId: (parentId: String) => {
    let NewLayer = []

    Explorer.layers.forEach(function (x, index) {
      if (parentId !== x.parentId && parentId !== x.id) {
        NewLayer.push(x)
      } else {
        if (parentId === x.parentId) {
          if ('tooltip' in x) {
            if (Object.keys(x.tooltip).length > 0) {
              x.tooltip.setMap(null)
            }
          }
        }
      }
    })
    Explorer.onUpdateLayer(NewLayer)
  },
  removeALlLayers: () => {
    // let NewLayer = []
    Explorer.layers.forEach(function (x) {
      if (typeof x.tooltip !== 'undefined') {
        if (Object.entries(x.tooltip).length > 0) {
          x.tooltip.setMap(null)
        }
      }
    })
    Explorer.onUpdateLayer([])
    if (Object.keys(Explorer.map).length > 0) {
      Explorer['deckgl'].setProps({layers: []})
    }
  },
  addIntersectLayer: (id: String, geom: JSON) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      if (id === x.id) {
        x.intersect = geom
        x.intersectId = id
        NewLayer.push(x)
      } else {
        x.intersect = null
        x.intersectId = null
        NewLayer.push(x)
      }
    })
    Explorer.onUpdateLayer(NewLayer)
  },
  removeIntersectLayer: (id: String) => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      if (id === x.id) {
        x.intersect = null
        x.intersectId = null
        NewLayer.push(x)
      } else {
        NewLayer.push(x)
      }
    })
    Explorer.onUpdateLayer(NewLayer)
  },
  removeAllIntersectLayer: () => {
    let NewLayer = []
    Explorer.layers.forEach(function (x, index) {
      x.intersect = null
      x.intersectId = null
      NewLayer.push(x)
    })
    Explorer.onUpdateLayer(NewLayer)
  },

  project: (latLng: google.maps.LatLng) => {
    let siny = Math.sin((latLng.lat() * Math.PI) / 180)
    const TILE_SIZE = 512
    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999)

    return new google.maps.Point(
      TILE_SIZE * (0.5 + latLng.lng() / 360),
      TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
    )
  },

  // intersectLayer: (geom: object) => {
  //   let NewLayer = []
  //   Explorer.layers.forEach(function (x) {
  //     if (typeof x.intersect[0] == 'undefined') {
  //       x.intersect = []
  //     }
  //     x.intersect.push(geom)
  //     NewLayer.push(x)
  //   })
  //   // Explorer.layers = NewLayer
  //   // Explorer.renderLayers()
  //   Explorer.onUpdateLayer(NewLayer)
  // },
  countQtdeLayer: (tipo) => {
    let count = 0
    Explorer.layers.forEach(function (layer) {
      if (layer.type === tipo) {
        count++
      }
    })
    return count
  },
  addLayerTile: (layer, label) => {
    let newLayer = {...layer}
    const id = uuid()
    var layerCreated = Explorer.create(layer, label)
    newLayer['id'] = id
    newLayer['map'] = layerCreated
    newLayer['nome'] = layer.nome
    newLayer['paint'] =
      layer.paint !== null && typeof layer.paint !== 'object'
        ? JSON.parse(layer.paint)
        : layer.paint
    newLayer['filter'] = layer.filter !== null ? layer.filter : {}
    newLayer['composite'] = layer.composite ? layer.composite.split(',') : []
    // Explorer.layers.push(newLayer)
    //Renderiza os layers no mapa
    // Explorer.renderLayers()
    // console.log(newLayer)
    //Atualiza os dados doos layer para atualizar outros componentes que depende dos layer
    Explorer.onAddLayer(newLayer)
    return id
  },
  create: (layer, label) => {
    return createLayer(
      {
        id: layer.id,
        tile: layer.tile,
        style: {
          ...(layer.paint !== null && typeof layer.paint !== 'object'
            ? JSON.parse(layer.paint)
            : layer.paint),
        },
        visible: layer.visible,
        label: layer.label,
        nome: layer.nome,
        filter: layer.filter !== null ? {...layer.filter} : {},
        data: layer.data,
        distance: layer.distance !== null ? layer.distance : null,
        intersectId: layer.intersectId,
        intersect: layer.intersect,
        operation: 'mask',
        selectedPolygon: layer.selectedPolygon,
        composite: layer.composite || [],
        color: layer.color || DEFAULT_COLOR,
        highlightColor: DEFAULT_HIGHLIGHT_COLOR,
        colorField: '',
        pickable: true,
        extruded: false,
        tooltip: layer.tooltip || null,
        positionZ: layer.positionZ,
        propriedades: layer.propiedades,
        icon: layer.icon,
        parentId: layer.parentId,
      },
      layer['type']
    )
  },
  drawTooltip(lat, lng, text) {
    var infowindow = new google.maps.InfoWindow({
      content: text,
      position: new google.maps.LatLng(lat, lng),
      pixelOffset: new google.maps.Size(0, -40),
    })
    // const popup = new Popup(
    //   new google.maps.LatLng(lng, lat),
    //   document.getElementById("content") as HTMLElement
    // );
    // popup.setMap(Explorer.map);
    infowindow.open(Explorer.map)
    return infowindow
  },
  drawTooltipDinamic(text) {
    var infowindow = new google.maps.InfoWindow({
      content: text,
      // position: new google.maps.LatLng(lat, lng),
      pixelOffset: new google.maps.Size(0, -40),
    })
    // const popup = new Popup(
    //   new google.maps.LatLng(lng, lat),
    //   document.getElementById("content") as HTMLElement
    // );
    // popup.setMap(Explorer.map);
    infowindow.open(Explorer.map)
    return infowindow
  },
  addLayer: (layer, label) => {
    let newLayer = {...layer}
    const id = uuid()
    layer['id'] = typeof layer['id'] === 'undefined' ? id : layer['id']
    var layerCreated = Explorer.create(layer, label)
    newLayer['id'] = layer['id']
    newLayer['map'] = layerCreated
    newLayer['paint'] =
      layer.paint !== null && typeof layer.paint !== 'object'
        ? JSON.parse(layer.paint)
        : layer.paint
    newLayer['filter'] = layer.filter !== null ? layer.filter : {}
    // Explorer.layers.push(newLayer)
    //Renderiza os layers no mapa
    // Explorer.renderLayers()
    // Explorer.onUpdateLayer(Explorer.layers)
    // Explorer.onAddLayer(newLayer)
    console.log(newLayer)
    Explorer.onAddLayer(newLayer)
    //Atualiza os dados doos layer para atualizar outros componentes que depende dos layer

    return id
  },

  addMarker: (lat, lng, nome, descricao: any = '', propriedades?: any, parentId?: any, visible:boolean = true) => {
    const data = [
      {
        name: nome,
        address: descricao,
        coordinates: [lng, lat],
      },
    ]
    let layer = {}
    var text = `${descricao ? descricao : nome}`
    layer['visible'] = visible
    layer['data'] = data
    layer['type'] = 'LayerIcon'
    layer['layout'] = ''
    layer['nome'] = `${nome}`
    layer['descricao'] = `${descricao ? descricao : nome}`
    layer['label'] = `${descricao ? descricao : nome}`
    layer['paint'] = {'fill-color': [93, 208, 89], opacity: 1}
    layer['tooltip'] = visible ? Explorer.drawTooltip(lat, lng, text) : null
    layer['propiedades'] = propriedades
    layer['parentId'] = parentId
    // layer['tootip'] = infowindow
    return Explorer.addLayer(layer, `${descricao ? descricao : nome}`)
  },
  addMarkerGeoJson: (data, nome, descricao: any = '') => {
    let layer = {}
    var text = `${descricao ? descricao : nome}`
    layer['visible'] = true
    layer['data'] = data
    layer['type'] = 'IconeLayerText'
    layer['layout'] = ''
    layer['nome'] = `${nome}`
    layer['descricao'] = `${descricao ? descricao : nome}`
    layer['label'] = `${descricao ? descricao : nome}`
    layer['paint'] = {'fill-color': [93, 208, 89], opacity: 1}
    layer['tooltip'] = true
    // layer['explorer_tiles'] =[
    //   {
    //     key_column: null,
    //     label: null,
    //     id_column: null,
    //     type_data: '',
    //     table_name: 'meuslocais',
    //     select_columns: 'nome',
    //     step: null,
    //     table_column: 'nome',
    //     description: 'nome',
    //     data_return: null,
    //     situacao: '1'
    //   }]
    layer['propiedades'] = data.propiedades

    // layer['tootip'] = infowindow
    return Explorer.addLayer(layer, `${descricao ? descricao : nome}`)
  },
  addRadius: (lat, lng, distance, title) => {
    const data = {
      center: [lng, lat],
      distance: distance,
      name: title,
      color: [255, 0, 0],
    }

    let layer = {}
    // layer['id'] = uuid()
    layer['visible'] = true
    layer['data'] = data
    layer['distance'] = distance
    layer['type'] = 'GeoJSONLayer'
    layer['layout'] = ''
    layer['nome'] = `  ${title}`
    layer['descricao'] = `  ${title}`
    layer['paint'] = {'fill-color': '#5dd059', 'fill-opacity': 0.5}
    Explorer.addLayer(layer, title)
  },
  addIsocrona: (geom, title) => {
    let layer = {}
    // layer['id'] = uuid()
    layer['visible'] = true
    layer['data'] = [geom]
    layer['type'] = 'LayerPolygon'
    layer['layout'] = ''
    layer['nome'] = `  ${title}`
    layer['descricao'] = `  ${title}`
    layer['paint'] = {'fill-color': '#5dd059', 'fill-opacity': 0.9}
    Explorer.addLayer(layer, title)
  },

  getCenter: () => {
    if (Object.keys(Explorer.map).length > 0) {
      return Explorer['map'].getCenter()
    }
  },
  addLayerLotes: () => {
    const imageMapType = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        // if (
        //   zoom < 17 ||
        //   zoom > 20
        //   // bounds[zoom][0][0] > coord.x ||
        //   // coord.x > bounds[zoom][0][1] ||
        //   // bounds[zoom][1][0] > coord.y ||
        //   // coord.y > bounds[zoom][1][1]
        // ) {
        //   return "";
        // }

        return [
          process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
          zoom,
          '/',
          coord.x,
          '/',
          coord.y,
          '.png?type=png',
        ].join('')
      },
      tileSize: new google.maps.Size(256, 256),
    })

    // if (showLotes){
    // if (Explorer.checkExistMap()){
    //   Explorer.map.overlayMapTypes.push(imageMapType)
    //   $.view.overlay.getPanes().overlayLayer.style['zIndex'] = 1001;
    // }
  },
  addIscota: (geom, title) => {
    let layer = {}
    // layer['id'] = uuid()
    layer['visible'] = true
    layer['data'] = [geom]
    layer['type'] = 'LayerPolygon'
    layer['layout'] = ''
    layer['nome'] = `  ${title}`
    layer['descricao'] = `  ${title}`
    layer['paint'] = {'fill-color': '#5dd059', 'fill-opacity': 0.9}

    Explorer.addLayer(layer, title)
  },

  addGeom: (geom, title, parentId?, paint?) => {
    let layer = {}
    layer['visible'] = true
    layer['data'] = [geom]
    layer['type'] = 'LayerPolygon'
    layer['layout'] = ''
    layer['nome'] = title
    layer['descricao'] = title
    layer['paint'] = paint
      ? paint
      : {'fill-color': '#004DE5', 'fill-opacity': 100, 'line-color': '#333', 'line-width':2 }
    layer['parentId'] = parentId
    return Explorer.addLayer(layer, title)
  },
  goToMapItem: (uuid: string) => {
    const layer = Explorer.getInfoLayer(uuid)
     //TODO: VErificar por que fica dando console log quando clica na formatação do layer
    // console.log(layer['type'])
    if (layer['type'] === 'LayerIcon') {
      const lat = layer['data'][0].coordinates[1]
      const lng = layer['data'][0].coordinates[0]
      if (Explorer.checkExistMap()) {
        //TODO : checar esse erro
        ;(Explorer.map as any).panTo(new google.maps.LatLng(lat, lng))
        Explorer.setGoTo(lat, lng)
      }
    }else if (layer['type'] === 'GeoJSONLayer' ) { 
      
      const centroid = turf.centroid(layer['data'][0])
      const lat = centroid.geometry.coordinates[1]
      const lng = centroid.geometry.coordinates[0]
      if (Explorer.checkExistMap()) {
        //TODO : checar esse erro
        ;(Explorer.map as any).panTo(new google.maps.LatLng(lat, lng))
        Explorer.setGoTo(lat, lng)
      }

    }else if (layer['type'] === 'LayerPolygon' ) { 
      // console.log(layer['data'])
      const centroid = turf.centroid(layer['data'][0])
      const lat = centroid.geometry.coordinates[1]
      const lng = centroid.geometry.coordinates[0]
      if (Explorer.checkExistMap()) {
        //TODO : checar esse erro
        ;(Explorer.map as any).panTo(new google.maps.LatLng(lat, lng))
        Explorer.setGoTo(lat, lng)
      }
    }

  },
  goToIconToMenu: (uuid: string) => {
    let itens = document.querySelectorAll('.item-list')
    itens.forEach((i, x) => {
      if (typeof i.classList != 'undefined') {
        i.classList.remove('active')
        let item = i.getElementsByClassName('collapse')
        item[0].classList.remove('show')
      }
    })
    itens.forEach((i, x) => {
      if (i.getAttribute('data-id') === uuid) {
        i.classList.add('active')
        let item = i.getElementsByClassName('collapse')
        item[0].classList.add('show')
      }
    })
  },
  checkExistMap: () => {
    if (Object.keys(Explorer.map).length > 0) {
      return true
    } else {
      return false
    }
  },

  addGeoJSONLayer: (data, lat, lng, title) => {
    let layer = {}
    // layer['id'] = uuid()
    layer['visible'] = true
    layer['data'] = data
    layer['type'] = 'GeoJSONLayer'
    layer['layout'] = ''
    layer['nome'] = `  ${title}`
    layer['descricao'] = `  ${title}`
    layer['paint'] = {'fill-color': '#5dd059', 'fill-opacity': 0.5}
    Explorer.addLayer(layer, title)
  },

  // addTile: (geom, title) => {
  //   let layer = {}
  //   // layer['id'] = uuid()
  //   layer['visible'] = true
  //   layer['data'] = [geom]
  //   layer['type'] = 'LayerPolygon'
  //   layer['layout'] = ''
  //   layer['nome'] = title
  //   layer['descricao'] = title
  //   // layer['tile'] = `https://tile.urbit.com.br/tile/${newLayer[0].source}/{z}/{x}/{y}.pbf?s=${newLayer[0].schema_table}`
  //   layer['paint'] = {'fill-color': '#5dd059', 'fill-opacity': 0.5}
  //   Explorer.addLayer(layer, title)
  // },
}
