import {Modal} from 'react-bootstrap'
import {
  MutationPutImovelSituacaoCliente,
  QueryGetImovelSituacaoClienteByID,
} from '../../../../graphql/services/ImovelSituacaoCliente'
import Swal from 'sweetalert2'
import {ErrorMessageComponent} from '../../../../backoffice/components/UI/ErrorMessage'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {MutationSetLogAcessoRecurso} from '../../../../graphql/services/LogAcesso'
import {useState} from 'react'
import {RgbToHex} from '../../../explorer/components/common'
import {ColorPickerChorme} from '../../../../components/UI/ColorPickerChorme'

interface situacaoTerrenoProps {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({id, show, closeModal}: situacaoTerrenoProps) {
  const [color, setColor] = useState(null)
  let {data, loading: LoadingGet} = QueryGetImovelSituacaoClienteByID({
    variables: {situacaoImovelClienteId: id},
  })

  const {FormImovelSituacaoCliente, loading, handleSubmit, register, errors} = MutationPutImovelSituacaoCliente()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    data.cor = color
    FormImovelSituacaoCliente(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'cadastro',
          funcionalidade: 'situacao-lotes',
          action: 'update',
          url: '/cadastro/situacao-lotes',
        })
        Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return <Loader text={'caregando...'}></Loader>
  }

  function onChange(color) {
    setColor(RgbToHex(color[0], color[1], color[2]))
  }
  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Situação de Terreno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={data ? data.GetOneImovelSituacaoCliente.id : ''}
              {...register('id', {required: true})}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='nome' className='required form-label'>
                    Nome:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Nome da Situação'
                    defaultValue={data ? data.GetOneImovelSituacaoCliente.nome : ''}
                    {...register('nome', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='descricao' className='required form-label'>
                    Descrição:
                  </label>
                  <textarea
                    style={{resize: 'none'}}
                    rows={4}
                    className='form-control form-control-solid'
                    placeholder='Descrição da Situação'
                    defaultValue={data ? data.GetOneImovelSituacaoCliente.descricao : ''}
                    {...register('descricao', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.descricao && (
                    <ErrorMessageComponent message={errors.descricao?.message} />
                  )}
                </div>
              </div>

              <div className='mb-15 col-12'>
                <div className=''>
                  <label htmlFor='descricao' className='required form-label'>
                    Cor
                  </label>
                  <ColorPickerChorme
                    defaultValue={data ? data.GetOneImovelSituacaoCliente.cor : null}
                    onChange={onChange}
                    register= {register('cor')}
                  ></ColorPickerChorme>
                  {errors.cor && <ErrorMessageComponent message={errors.cor?.message} />}
                </div>
              </div>
              <div className='mb-6 d-flex'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-2'>
                  <label>
                    <input
                      className=' form-check-input'
                      type='checkbox'
                      id='status'
                      defaultChecked={
                        data
                          ? data.GetOneImovelSituacaoCliente.status === 1
                            ? true
                            : false
                          : false
                      }
                      {...register('status', {required: false})}
                    />
                    Ativado
                  </label>
                  {errors.status && <ErrorMessageComponent message={errors.status?.message} />}
                </div>
              </div>
              <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
