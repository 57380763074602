import ReactPivot from 'react-pivot-webpack'
import {useEffect, useRef, useState} from 'react'
import {Template} from '../../../../../Util/TemplateReplace'
import {BoxPrice} from './BoxPrice'
import {Endereco} from '../../../../ComponentsControl/EnderecoControl/endereco'
import {Loader} from '../../../../../../../../components/UI/Loader/Loader'
import { ApiUrbit } from '../../../../../../../../../infra/http/urbit'


interface Props {
  itens: []
  latLng: google.maps.LatLng
}
export const Lancamentos: React.FC<Props> = ({itens, latLng}) => {
  const lat = latLng.lat()
  const lng = latLng.lng()
  const [result, setResult] = useState(null)
  const [error, setError] = useState(false)
  const [municipio, setMunicipio] = useState(null)
  const [distancia, setDistancia] = useState(500)
  const ref = useRef()

  useEffect(() => {
    async function getMunicipio(lat, lng) {
      const municipioResult = await Endereco.getMunicipioAPI(lat, lng)
      setMunicipio(municipioResult)
      return municipioResult
    }

    getMunicipio(lat, lng)
  }, [lat, lng])

  useEffect(() => {
    async function fetchData() {
      const data = await ApiUrbit.getServico(
        'orulo-tipologias',
        lat,
        lng,
        municipio.municipio_url,
        distancia
      )
      if (data) {
        console.log('data')
        console.log(data)
        if (typeof data.message !== 'undefined') {
          setError(true)
          // setResult(null)
        } else {
          // setError(false)
          setResult(data)
        }
      } else {
        setError(true)
        setError(null)
      }
    }
    if (municipio) {
      fetchData()
    }
    return () => {
      setResult(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipio, latLng, distancia])

  if (!municipio) {
    return <Loader text={'Carregando...'}></Loader>
  }

  function onChangeDistancia(e) {
    setDistancia(Number(e.target.value))
  }

  var dimensions = [
    {value: 'quartos', title: 'Quartos'},
    {value: 'tipo', title: 'Tipo'},
    {value: 'banheiros', title: 'Banheiros'},
    {value: 'vagas', title: 'Vaga'},
    {value: 'empreendimento', title: 'Empreendimento'},
  ]

  var reduce = function (row, memo) {
    if (row.preco !== null) {
      memo.count = (memo.count || 0) + 1
      memo.preco = (memo.preco || 0) + (row.preco ? parseFloat(row.preco) : 0)
      memo.media = (memo.preco || 0) / memo.count
      memo.area_privativa = (memo.area_privativa || 0) + parseFloat(row.area_privativa)
    }

    return memo
  }

  var calculations = [
    {
      title: 'Qtde',
      value: function (memo) {
        return memo.count
      },
      template: function (val, row) {
        if (val) return val.toFixed(0)
      },
      sortBy: function (row) {
        return isNaN(row.count) ? 0 : row.count
      },
      className: 'text-center',
    },
    {
      title: 'Preços ( R$/M2)',
      value: function (memo) {
        return isNaN(memo.preco)
          ? 0
          : memo.preco / (isNaN(memo.area_privativa) ? 0 : memo.area_privativa)
      },
      template: function (val, row) {
        return Template.formatNumber(val.toFixed(2), ``)
      },
      className: 'text-right',
    },
    {
      title: 'Ticket Médio (R$/Unidade)',
      value: function (memo) {
        return isNaN(memo.preco) ? 0 : memo.preco / (isNaN(memo.count) ? 0 : memo.count)
      },
      template: function (val, row) {
        return Template.formatNumber(val.toFixed(2), ``)
      },
      className: 'text-right',
    },
    {
      title: 'Área Privativa (m²)',
      value: function (memo) {
        return isNaN(memo.area_privativa)
          ? 0
          : memo.area_privativa / (isNaN(memo.count) ? 0 : memo.count)
      },
      template: function (val, row) {
        return val.toFixed(0)
      },
      className: 'text-center',
    },
  ]
  console.log(result)
  console.log(error)

  if (!error) {
    if (!result) {
      return <Loader text={'Carregando...'}></Loader>
    }
  }

  var precoMedioM2 = 0
  var ticketMedioM2 = 0
  var arePrivativaMediaM2 = 0
  var totalItens = 0

  if (result) {
    var rows = result.itens?.filter((item) => item.preco && item.preco > 0.1)

    console.log(result)
    if (result) {
      if (Object.entries(result).length > 0) {
        if (result.itens.length > 0) {
          // eslint-disable-next-line array-callback-return
          result.itens.map(function (element) {
            if (element.preco !== null && element.preco > 0.1) {
              totalItens += 1
              precoMedioM2 += (element.preco || 0) / element.area_privativa
              ticketMedioM2 += element.preco || 0
              arePrivativaMediaM2 += element.area_privativa || 0
            }
          })
          precoMedioM2 = precoMedioM2 / totalItens
          ticketMedioM2 = ticketMedioM2 / totalItens
          arePrivativaMediaM2 = arePrivativaMediaM2 / totalItens
        }
      } else {
        precoMedioM2 = 0
        ticketMedioM2 = 0
        arePrivativaMediaM2 = 0
      }
    }
  }

  console.log(precoMedioM2)
  console.log(ticketMedioM2)
  console.log(arePrivativaMediaM2)
  console.log('error')
  console.log(error)
  console.log('result')
  console.log(result)
  return (
    <>
      <div className='d-flex justify-content-between justify-content-center align-items-center'>
        <h1 className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 py-10 mx-1 m-0 col-6'>
          Lançamentos
          <br />
          <small className='fs-6 fw-light'> Unidades em estoque</small>
        </h1>
        <div className='d-flex justify-content-end col-6 '>
          <label className=' col-2 mt-3 mx-2'>Distância(Raio)</label>
          <select
            id='distance'
            className='form-control distance col-2 w-100px mx-2'
            onChange={onChangeDistancia}
          >
            <option value='500'>500 m</option>
            <option value='1000'>1 km</option>
            <option value='1500'>1.5 km</option>
            <option value='2000'>2 km</option>
          </select>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          {error ? (
            <div>Este serviço não existe para esta cidade. </div>
          ) : result ? (
            <ReactPivot
              ref={ref}
              tableClassName='table table-striped'
              rows={rows}
              dimensions={dimensions}
              activeDimensions={['Tipo', 'Quartos']}
              reduce={reduce}
              calculations={calculations}
              soloText={'Filtro'}
              subDimensionText={'Agrupamento'}
            />
          ) : (
            <Loader text={'Carregando...'}></Loader>
          )}
        </div>

        <div className='col-md-6'>
          <div className='row'>
            <div className='col-xl-4'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(precoMedioM2, ''))}
                description='Preços ( R$/M2)'
              />
            </div>
            <div className='col-xl-4'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(ticketMedioM2, ''))}
                description='Ticket Médio (R$/Unidade)'
              />
            </div>
            <div className='col-xl-4'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(arePrivativaMediaM2, '')) + 'm²'}
                description='Área Privativa (m²)'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
