import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { zodResolver } from '@hookform/resolvers/zod';
import { TypologyUpdateFormSchema, TypologyUpdateFormData } from './formValidations';
import { getTypologyByID, updateTypology } from '../../../../../../services/urbit-gis';
import { useParams } from 'react-router-dom';
import { useEmpreendimentos } from '../../context/useEmpreendimentos';
import { MoneyInput } from '../../../../../components/UI/Inputs/MoneyInput';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { TypologyData } from '../../../../../../services/urbit-gis/types';
import { AreaInput } from '../../../../../components/UI/Inputs/AreaInput';

type Props = {
  id: number;
}

export function ModalUpdateTypologies({ id }: Props) {
  const { id: idEmpreendimento } = useParams<{ id: string }>();
  const { refetchTypologies } = useEmpreendimentos(); // Hook importado
  const [dataTypology, setDataTypology] = useState<TypologyData | null>(null);
  const { register, handleSubmit, setValue, formState: { errors }, reset, control } = useForm<TypologyUpdateFormData>({
    resolver: zodResolver(TypologyUpdateFormSchema),
  });

  const fetchData = async (id: number) => {
    try {
      const result = await getTypologyByID(id);
      setDataTypology(result);

    } catch (error) {
      console.error('Failed to fetch typology data', error);
      // Handle error appropriately
    }
  };

  const [show, setShow] = useState(false);

  const closeModal = () => setShow(false);
  const openModal = async () => {
    setDataTypology(null)
    await fetchData(id);
    setShow(true);
  };

  const onSubmit = async (data: TypologyUpdateFormData) => {
    try {
      data.id_real_estate_launches = parseInt(idEmpreendimento);
      const result = await updateTypology(data.id, data);
      if (result && result.service !== 'error') {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Tipologia atualizada com sucesso.',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        closeModal();
        reset();
        if (idEmpreendimento) {
          await refetchTypologies(parseInt(idEmpreendimento)); // Atualiza a lista de tipologias
        }
      } else {
        throw new Error(result.message || 'Ocorreu um erro desconhecido');
      }
    } catch (error) {
      let errorMessage = 'Houve um erro ao atualizar a tipologia.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      Swal.fire({
        title: 'Erro!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };


  return (
    <>
      <button type='button' onClick={openModal} disabled={!idEmpreendimento} className='btn btn-icon btn-light-success me-2' style={{ width: '100px !important' }}>
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>

      <Modal centered animation show={show} onHide={closeModal} size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Tipologia</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-lg  pt-2'>
          <div>
            <input type="hidden" defaultValue={id} {...register('id', { valueAsNumber: true })} />
            <input type="hidden" defaultValue={parseInt(idEmpreendimento)} {...register('id_real_estate_launches', { valueAsNumber: true })} />
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="type">Tipo:</label>
                  <select id="type" className='form-select' {...register('type')} defaultValue={dataTypology ? dataTypology.type : ""}>
                    <option value="">Selecione</option>
                    <option value="Casa">Casa</option>
                    <option value="Sala">Sala</option>
                    <option value="Loft">Loft</option>
                    <option value="Loja">Loja</option>
                    <option value="Duplex">Duplex</option>
                    <option value="Studio">Studio</option>
                    <option value="Garden">Garden</option>
                    <option value="Triplex">Triplex</option>
                    <option value="Apartamento">Apartamento</option>
                    <option value="Cobertura Duplex">Cobertura Duplex</option>
                    <option value="Cobertura Horizontal">Cobertura Horizontal</option>
                    <option value="Galpão/Pavilhão">Galpão/Pavilhão</option>
                    <option value="Casa em Condomínio">Casa em Condomínio</option>
                    <option value="Sala com Área Ext.">Sala com Área Ext.</option>
                    <option value="Terreno/Lote Comercial">Terreno/Lote Comercial</option>
                    <option value="Terreno/Lote Residencial">Terreno/Lote Residencial</option>
                  </select>
                  {errors.type && <span className="text-danger">{errors.type.message}</span>}
                </div>
              </div>
              <div className="col-4">
                <AreaInput
                  solid={false}
                  label="Área:"
                  name="private_area"
                  control={control}
                  valueDefault={dataTypology ? dataTypology.private_area : null}
                  error={errors.private_area?.message}
                />
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="total_units">Total de Unidades:</label>
                  <input id="total_units" className='form-control' placeholder='Ex: 100'
                    defaultValue={dataTypology ? dataTypology.total_units : null}
                    type="number"
                    {...register('total_units', { valueAsNumber: true })}
                    onChange={(e) => {
                      setValue('stock', parseInt(e.target.value))
                    }}
                    aria-describedby="basic-addon2" />
                  {errors.total_units && <span className="text-danger">{errors.total_units.message}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="bedrooms">Quartos:</label>
                  <select id="bedrooms" className='form-select'
                    defaultValue={dataTypology ? dataTypology.bedrooms : 0}
                    {...register('bedrooms', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.bedrooms && <span className="text-danger">{errors.bedrooms.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="suites">Suítes:</label>
                  <select id="suites" className='form-select'
                    defaultValue={dataTypology ? dataTypology.suites : 0}
                    {...register('suites', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.suites && <span className="text-danger">{errors.suites.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="parking">Vagas:</label>
                  <select id="parking" className='form-select'
                    defaultValue={dataTypology ? dataTypology.parking : 0}
                    {...register('parking', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.parking && <span className="text-danger">{errors.parking.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className='col-form-label' htmlFor="bathrooms">Banheiros:</label>
                  <select id="bathrooms" className='form-select'
                    defaultValue={dataTypology ? dataTypology.bathrooms : 0}
                    {...register('bathrooms', { valueAsNumber: true })}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5+</option>
                  </select>
                  {errors.bathrooms && <span className="text-danger">{errors.bathrooms.message}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MoneyInput
                  label="Valor do Condomínio:"
                  name="condominium_value"
                  valueDefault={dataTypology ? dataTypology.condominium_value : null}
                  control={control}
                  error={errors.condominium_value?.message}
                />
              </div>
              <div className="col-6">
                <MoneyInput
                  label="Valor do IPTU:"
                  name="urban_land_tax"
                  valueDefault={dataTypology ? dataTypology.urban_land_tax : null}
                  control={control}
                  error={errors.urban_land_tax?.message}
                />
              </div>
            </div>
            <div className="text-end mt-4">
              <button type="button" onClick={handleSubmit(onSubmit)} className="btn btn-primary">Salvar</button>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}
