import { AxiosHttpClientAdapter } from '../../adapters/axios-adapter';
import { HttpClient } from '../../adapters/type';

const token = localStorage.getItem('token')
export class ApiN8N {
  host: string = process.env.REACT_APP_N8N;
  bearer: string = token;
  clienteConect: any;

  constructor(private httpClientRequest: HttpClient) {
    this.clienteConect = httpClientRequest;
  }

  init() {
    // Configurações iniciais
    return [];
  }

  setBearer(bearer: string) {
    this.bearer = bearer;
  }

  setHost() {
    // Configuração do host
    // this.host = 'https://api.urbit.com.br/'
  }

  async request(method: string, url: string, data = '') {
    let body = data ? JSON.stringify(data) : null;

    const result = await this.clienteConect.request({
      url: this.host + url,
      method: method,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${this.bearer}`,
      },
      body,
    });

    if (result.statusCode === 200) {
      return result.body;
    } else {
      return { service: 'error', message: `Erro status: ${result.status}`, itens: [], total: 0 };
    }
  }

  async get(url: string, callback?: any) {
    if (typeof callback == 'function') {
      fetch(this.host + url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.bearer}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            return null
          }
        })
        .then((data) => {
          if (!data || typeof data === 'undefined') {
            callback({ service: 'erro', message: data, itens: [], total: 0 })
          } else {
            callback(data)
          }
        })
        .catch((err) => {
          callback({ service: 'error', message: err.message, itens: [], total: 0 })
        })
    } else {
      try {
        return this.request('GET', url)
      } catch (error) {
        console.error('Error:', error)
        return { service: 'error', message: error, itens: [], total: 0 }
      }
    }
  }

  async post(url: string, dataInput: any, callback?: any) {
    if (typeof callback === 'function') {
      fetch(this.host + url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${this.bearer}`,
        },
        body: JSON.stringify(dataInput),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return null; // ou pode retornar Promise.reject(response) para propagar o erro
          }
        })
        .then((data) => {
          if (!data) {
            callback({ service: 'erro', message: 'Dados não encontrados', itens: [], total: 0 });
          } else {
            callback(data);
          }
        })
        .catch((err) => {
          callback({ service: 'error', message: err.message || 'Erro desconhecido', itens: [], total: 0 });
        });
    } else {
      return fetch(this.host + url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${this.bearer}`,
        },
        body: JSON.stringify(dataInput),
      }).then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Erro ao processar a requisição');
        }
      }).catch(error => {
        throw new Error(error.message || 'Erro desconhecido');
      });
    }
  }


  async put(url: string, dataInput: any, callback?: any) {
    if (typeof callback === 'function') {
      try {
        const response = await fetch(this.host + url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${this.bearer}`,
          },
          body: JSON.stringify(dataInput),
        });
  
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
  
        const data = await response.json();
        callback(data);
      } catch (err) {
        callback({ service: 'error', message: err.message || 'Erro desconhecido', items: [], total: 0 });
      }
    } else {
      try {
        const response = await fetch(this.host + url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${this.bearer}`,
          },
          body: JSON.stringify(dataInput),
        });
  
        if (!response.ok) {
          throw new Error('Erro ao processar a requisição');
        }
  
        return response.json();
      } catch (err) {
        throw new Error(err.message || 'Erro desconhecido');
      }
    }
  }
  
  
  async delete(url: string, callback?: any) {
    if (typeof callback == 'function') {
      fetch(this.host + url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.bearer}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return null;
          }
        })
        .then((data) => {
          if (!data || typeof data === 'undefined') {
            callback({ service: 'erro', message: data, itens: [], total: 0 });
          } else {
            callback(data);
          }
        })
        .catch((err) => {
          callback({ service: 'error', message: err.message, itens: [], total: 0 });
        });
    } else {
      try {
        return this.request('DELETE', url);
      } catch (error) {
        console.error('Error:', error);
        return { service: 'error', message: error, itens: [], total: 0 };
      }
    }
  }
}

export const APIN8N = new ApiN8N(new AxiosHttpClientAdapter());
