import React, { useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { deleteTypologyPrice } from '../../../../../../../services/urbit-gis'



export function Buttons({ id, setId, handleRefetch, setShowUpdate }) {

  function confirmDelete(id) {
    Swal.fire({
      title: 'Deseja excluir?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const result = await deleteTypologyPrice(id)
        if (result) {
          Swal.fire('Sucesso!', 'Preço deletado com sucesso.', 'success')
          handleRefetch()
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <button
          type='button'
          className='btn btn-icon btn-light-success me-2'
          onClick={() => {
            setId(id)
            setShowUpdate(true)
            // console.log(id)
          }}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          type='button'
          className='btn btn-icon btn-light-danger  btn-sm me-1'
          onClick={() => {
            confirmDelete(id)
          }}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      </div>
    </>
  )
}
