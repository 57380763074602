import {useEffect, useState} from 'react'
import { formatAdressSeachGoogleMapa } from '../../explorer/components/common'
import { useTeseStore } from '../context/DataContext'
import { Endereco } from '../context/types'

const useAutocomplete = (map: any) => {
  const [marcador, setMarcador] = useState([])
  const [buscaPosition, setBuscaPosition] = useState('')
  const [autocomplete, setAutocomplete] = useState(null)
  const {setEndereco,endereco} = useTeseStore()

  function setMarcadorAddress(map: any, endereco: Endereco) {
    if (Object.entries(map).length > 0) {
      map.panTo(new google.maps.LatLng(endereco.latitude, endereco.longitude))
    }
    setMarcador([
      ...marcador,
      ...[
        {
          lat: endereco.latitude,
          lng: endereco.longitude,
          endereco: endereco.endereco_completo,
        },
      ],
    ])
  }

  useEffect(()=>{
    if(endereco){
      setBuscaPosition('')
    }
  },[endereco])

  function onPlaceChanged(map: any) {
    setBuscaPosition('')
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (typeof place.geometry !== 'undefined') {
        const enderecoFormatado = formatAdressSeachGoogleMapa(autocomplete.getPlace())
        setMarcadorAddress(map, enderecoFormatado)
        setEndereco({...enderecoFormatado})
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  function onLoadAutoComplete(autocomplete: any) {
    setAutocomplete(autocomplete)
  }

  return {
    marcador,
    setMarcador,
    buscaPosition,
    setBuscaPosition,
    setAutocomplete,
    onPlaceChanged,
    onLoadAutoComplete,
    setMarcadorAddress,
  }
}

export default useAutocomplete
