import {gql} from '@apollo/client'

// Listagem de todos os empreendimentos
export const GET_EMPREENDIMENTOS = gql`
  query GetEmpreendimentos($pagination: Pagination!,$filtro: filter, $situation: Float) {
    GetEmpreendimentos(pagination: $pagination, filtro: $filtro, situation: $situation) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        nome
        id
        endereco
        area_total_terreno
        area_total_privativa
        arquiteto
        parceiro
        parceiro_nome
        parceiro_percentual
        parceiro_percentual_proprio
        preco_medio_m2
        percentual_comissao
        responsavel
        situacao_id
        tag
        tipo_id
        user_id
        vgv
        users {
          name
          id
        }
        empreendimento_terreno{
          terreno{
            id
            nome
            endereco
            numero
            zoneamento
            bairro
            latitude
            longitude
            marcacao
            cidade
            operacao_urbana
            numero_contribuinte
            terreno_situacao_cliente {
              id
              nome
              cor
            }
            users{
              id
              name
            }
          }
        }
        empreendimento_temperatura {
          id
          nome
          cor
        }
        empreendimento_situacao {
          id
          nome
          cor
        }
      }
    }
  }
`

export const GET_EMPREENDIMENTOS_OFF = gql`
  query GetEmpreendimentosOff($pagination: Pagination!) {
    GetEmpreendimentosOff(pagination: $pagination) {
      id
      nome
      endereco
    }
  }
`

// Listagem de um unico empreendimento
export const GET_EMPREENDIMENTO = gql`
  query GetEmpreendimento($idEmpreendimento: Float!) {
    GetEmpreendimento(id_empreendimento: $idEmpreendimento) {
      id
      uuid
      nome
      situacao_id
      endereco
      area_total_privativa
      area_total_terreno
      preco_medio_m2
      arquiteto
      vgv
      responsavel
      tag
      parceiro_nome
      parceiro_percentual
      parceiro_percentual_proprio
      preco_m2_venda
      percentual_comissao
      vgv_proprio
      empreendimento_temperatura {
        id
        nome
        cor
      }
      users{
        id
        name
      }
    }
  }
`
// Listagem de todos os empreendimentos
export const GET_COUNT_EMPREENDIMENTOS = gql`
  query GetCountEmpreendimentos {
    CountEmpreendimentos
  }
`
