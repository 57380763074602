import {FaSave} from 'react-icons/fa'
import {Endereco} from '../../ComponentsControl/EnderecoControl/endereco'
import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Loader} from '../../../../../../components/UI/Loader/Loader'
import {errorHandler} from '../../../../../../graphql/errorHandler'
import {useMutation} from '@apollo/client'
import {SetImovelResponse} from '../../../../../../graphql/type'
import {SET_IMOVEL} from '../../../../../../graphql/mutations'
import {GET_IMOVEIS} from '../../../../../../graphql/query'
import {formatAdressSeachGoogleMapa} from '../../../common'
import { ApiUrbit } from '../../../../../../../infra/http/urbit'


type Props = {
  latLng: google.maps.LatLng
  municipio: any
}

type Message = {
  error: boolean
  message: string
  data: any
}

interface Imovel {
  nome: string
  endereco: string
  numero: number
  complemento: string
  bairro?: string
  cidade: string
  id_cidade: number
  id_estado: number
  estado: string
  cep: string
  marcacao: string
  area_iptu: number
  area_real: number
  numero_contribuinte: string
  numero_matricula?: string
  latitude: string
  longitude: string
  setor_quadra: string
  terrain_type: string
  postgis_municipio_id: number
  uso_atual_terreno: string
  codlog: string
  terreno_esquina?: string
  numero_lote: string
  testada?: number
  preco_m2?: number
  cod_condomio?: string
  id_situacao: number
}

export function SaveImovel({latLng, municipio}: Props) {
  const [resultLote, setResultLote] = useState<Imovel>()
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState<Message>({error: null, message: null, data: null})
  const lat = latLng.lat()
  const lng = latLng.lng()
  const [createImovel] = useMutation<SetImovelResponse>(SET_IMOVEL, {
    refetchQueries: [GET_IMOVEIS],
  })
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  useEffect(() => {
    async function getLote(lat, lng, municipio) {
      if (municipio.lote_fiscal === 1) {
        const resultado = await ApiUrbit.getServico(
          'lote-fiscal',
          lat,
          lng,
          municipio['municipio_url'],
          0
        )

        const lote = resultado.itens[0]
        if (lote) {
          setResultLote({
            nome: lote.logradouro + ',' + lote.numero,
            endereco: lote.logradouro,
            numero: lote.numero,
            complemento: lote.complemento,
            cep: lote.cep,
            cidade: municipio['nome'],
            estado: municipio['id_estado'],
            terrain_type: 'IMOVEL',
            postgis_municipio_id: municipio['id'],
            id_cidade: Number(lote.id_cidade),
            id_estado: Number(lote.id_estado),
            area_iptu: Number(lote.area_lote),
            area_real: Number(lote.area_lote),
            numero_contribuinte: lote.numero_contribuinte,
            latitude: String(lat),
            longitude: String(lng),
            setor_quadra: lote.setor + '' + lote.quadra,
            uso_atual_terreno: lote.uso_atual,
            codlog: lote.codlog,
            numero_lote: lote.numero_lote,
            testada: Number(lote.testada),
            preco_m2: Number(lote.valor_do_m2_do_terreno),
            cod_condomio: lote.lote === '0000' ? '00' : null,
            id_situacao: 1,
            marcacao: lote.geom,
          })
        } else {
          setMessage({
            error: true,
            message: 'Imóvel não encontrado, coloque o marcador em cima de um lote.',
            data: null,
          })
        }
      } else {
        const geocoder = new google.maps.Geocoder()
        const latLng = new google.maps.LatLng(lat, lng)
        geocoder.geocode({location: latLng}, async function (results, status) {
          if (status === 'OK') {
            var place = results[0]
            var adr_elements = formatAdressSeachGoogleMapa(place)
            const geom = {
              type: 'Point',
              coordinates: [lng, lat],
            }

            municipio = await Endereco.getMunicipioAPI(lat, lng)

            setResultLote({
              numero: adr_elements['numero'],
              nome: adr_elements['logradouro'] + ',' + adr_elements['numero'],
              endereco: adr_elements['logradouro'],
              cidade: municipio['nome'],
              bairro: adr_elements['bairro'],
              estado: adr_elements['estado'],
              id_cidade: municipio.id,
              id_estado: municipio.id_estado,
              terrain_type: 'IMOVEL',
              postgis_municipio_id: municipio['id'],
              complemento: '',
              cep: adr_elements['cep'],
              area_iptu: undefined,
              area_real: undefined,
              numero_contribuinte: '',
              latitude: String(lat),
              longitude: String(lng),
              setor_quadra: '',
              uso_atual_terreno: '',
              codlog: '',
              terreno_esquina: '',
              numero_lote: '',
              id_situacao: 1,
              testada: undefined,
              preco_m2: undefined,
              marcacao: JSON.stringify(geom),
            })
          }
        })
      }
    }

    getLote(lat, lng, municipio)

    return () => {
      setMessage({
        error: null,
        message: null,
        data: null,
      })
    }
  }, [lat, lng, municipio])

  async function saveLocal() {
    await createImovel({
      variables: {
        imovel: resultLote,
      },
    })
      .then(async (result) => {
        if (!result.data) {
          let erroText = errorHandler(result.errors)
          setMessage({
            error: true,
            message: erroText,
            data: result.errors[0]['extensions']['exception']['data'],
          })
        } else {
          setMessage({
            error: true,
            message: `Endereço "${result.data.SetImovel.nome}" cadastrado com sucesso. `,
            data: result.data.SetImovel,
          })
        }
      })
      .catch((erro) => {
        console.log(erro)
      })
  }
  async function checkLocal() {
    setShow(true)
  }
  const handleClose = () => {
    setMessage({
      error: null,
      message: null,
      data: null,
    })
    setShow(false)
  }
  return (
    <>
      <button className='btn btn-primary' onClick={checkLocal}>
        <FaSave className='fs-4 me-1 mt-0' /> Salvar Local
      </button>
      <Modal centered animation show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Salvar Local</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message.error ? (
            <p className='fs-3'>{message.message}</p>
          ) : !resultLote && message ? (
            <Loader text={'caregando...'}></Loader>
          ) : (
            `Deseja salvar o lote ${resultLote['endereco']},${resultLote['numero']} .`
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' data-dismiss='modal' onClick={handleClose}>
            Close
          </Button>
          {message.data ? (
            <button
              onClick={() => {
                openInNewTab(`/meus-imoveis/details/${message.data.id}?idCidade=${municipio.id}`)
                handleClose()
              }}
              className='btn btn-info '
            >
              Abrir Detalhes
            </button>
          ) : (
            ''
          )}

          {!message.error ? (
            <Button variant='primary' data-dismiss='modal' onClick={saveLocal}>
              Salvar Local
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
