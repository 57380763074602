import ReactPivot from 'react-pivot-webpack'
import {useEffect, useState} from 'react'
import {Template} from '../../../../../Util/TemplateReplace'
import {BoxPrice} from './BoxPrice'
import {removeItensObj} from '../../../../../../../../components/util'
import * as XLSX from 'xlsx'
import {Loader} from '../../../../../../../../components/UI/Loader/Loader'
import { ApiUrbit } from '../../../../../../../../../infra/http/urbit'

interface Props {
  itens: []
  latLng: google.maps.LatLng
}
export const ApartamentosVenda: React.FC<Props> = ({itens, latLng}) => {
  const lat = latLng.lat()
  const lng = latLng.lng()
  const [result, setResult] = useState(null)

  const [distancia, setDistancia] = useState(500)

  function onChangeDistancia(e) {
    setDistancia(Number(e.target.value))
  }

  useEffect(() => {
    async function fetchData() {
      const data = await ApiUrbit.getServico(
        'apartamentos-ofertas',
        lat,
        lng,
        'sao-paulo',
        distancia
      )
      setResult(data)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latLng, distancia])

  var dimensions = [
    {value: 'quartos', title: 'Quartos'},
    {value: 'tipo', title: 'Tipo'},
    {value: 'banheiros', title: 'Banheiros'},
    {value: 'vaga', title: 'Vaga'},
    {value: 'endereco', title: 'Endereço'},
    {value: 'area', title: 'Área'},
  ]

  var reduce = function (row, memo) {
    memo.count = (memo.count || 0) + 1
    memo.valor = (memo.valor || 0) + parseFloat(row.valor)
    memo.media = (memo.valor || 0) / memo.count
    memo.area = (memo.area || 0) + parseFloat(row.area)
    return memo
  }

  var calculations = [
    {
      title: 'Qtde',
      value: function (memo) {
        return memo.count
      },
      template: function (val, row) {
        return val.toFixed(0)
      },
      sortBy: function (row) {
        return isNaN(row.count) ? 0 : row.count
      },
      className: 'text-center',
    },
    {
      title: 'Preços ( R$/M2)',
      value: function (memo) {
        return memo.valor / memo.area
      },
      template: function (val, row) {
        return Template.formatNumber(val.toFixed(2), ``)
      },
      className: 'text-right',
    },
    {
      title: 'Ticket Médio (R$/Unidade)',
      value: function (memo) {
        return memo.valor / memo.count
      },
      template: function (val, row) {
        return Template.formatNumber(val.toFixed(2), ``)
      },
      className: 'text-right',
    },
    {
      title: 'Área Privativa (m²)',
      value: function (memo) {
        return memo.area / memo.count
      },
      template: function (val, row) {
        return val.toFixed(0)
      },
      className: 'text-center',
    },
  ]
  if (!result) {
    return <Loader text={'Carregando...'}></Loader>
  }
  if (result) {
    var rows = result.itens
    var precoMedioM2 = 0
    var ticketMedioM2 = 0
    var arePrivativaMediaM2 = 0
    var totalItens = 0
    if (Object.entries(result).length > 0) {
      // eslint-disable-next-line array-callback-return
      result.itens.map(function (element) {
        totalItens += 1
        precoMedioM2 += element.valor / element.area
        ticketMedioM2 += element.valor
        arePrivativaMediaM2 += element.area
      })
      precoMedioM2 = precoMedioM2 / totalItens
      ticketMedioM2 = ticketMedioM2 / totalItens
      arePrivativaMediaM2 = arePrivativaMediaM2 / totalItens
    }
  }

  const handleExport = () => {
    let dataexport = []
    // eslint-disable-next-line array-callback-return
    result.itens.map(function (i) {
      dataexport.push(removeItensObj(i))
      // return removeItensObj(i, removeColunas)
    })
    const worksheet = XLSX.utils.json_to_sheet(dataexport)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Tabela de Dados')
    XLSX.writeFile(wb, 'dados.xlsx')
  }

  return (
    <>
      <div className='d-flex justify-content-between justify-content-center align-items-center'>
        <h1 className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 py-10 mx-1 m-0 col-6'>
          Apartamentos à Venda
          <br />
          <small className='fs-6 fw-light'>Últimos 90 dias. </small>
        </h1>

        <div className='d-flex justify-content-end col-6 '>
          <label className=' col-2 mt-3 mx-2'>Distância(Raio)</label>
          <select
            id='distance'
            className='form-control distance col-2 w-100px mx-2'
            onChange={onChangeDistancia}
          >
            <option value='500'>500 m</option>
            <option value='1000'>1 km</option>
            <option value='1500'>1.5 km</option>
            <option value='2000'>2 km</option>
          </select>
          <button onClick={handleExport} className='btn btn-primary ms-2'>
            Exportar para XLSX
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-8 col-xl-6'>
          {result ? (
            <ReactPivot
              tableClassName='table table-striped'
              rows={rows}
              dimensions={dimensions}
              activeDimensions={['Tipo', 'Quartos']}
              reduce={reduce}
              calculations={calculations}
              soloText={'Filtro'}
              subDimensionText={'Agrupamento'}
            />
          ) : (
            <div>Carregando...</div>
          )}
        </div>

        <div className='col-md-4 col-xl-6'>
          <div className='row'>
            <div className='col-xl-4'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(precoMedioM2, ''))}
                description='Preços ( R$/M2)'
              />
            </div>
            <div className='col-xl-4'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(ticketMedioM2, ''))}
                description='Ticket Médio (R$/Unidade)'
              />
            </div>
            <div className='col-xl-4'>
              <BoxPrice
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='success'
                title={String(Template.formatNumber(arePrivativaMediaM2, '')) + 'm²'}
                description='Área Privativa (m²)'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
